import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteLoadPatientInfo implements LoadPatientInfo {
  constructor(private readonly patientRepository: PatientRepository) {}

  load(
    params?: LoadPatientInfo.Params,
    patient_id?: number
  ): Promise<LoadPatientInfo.Model> {
    return this.patientRepository.loadPatientInfo(params, patient_id)
  }
}

export type LoadPatientInfoModel = LoadPatientInfo.Model
