import { makeAutoObservable } from 'mobx'
import type { CreateDoctorDocumentValidation } from 'domain/usecases/doctor-document-validation/create-doctor-document-validation'
import type { DeleteDoctorDocumentValidation } from 'domain/usecases/doctor-document-validation/delete-doctor-document-validation'
import type { UpdateDoctorDocumentValidation } from 'domain/usecases/doctor-document-validation/update-doctor-document-validation'

type Services = {
  createDoctorDocumentValidation: CreateDoctorDocumentValidation
  deleteDoctorDocumentValidation: DeleteDoctorDocumentValidation
  updateDoctorDocumentValidation: UpdateDoctorDocumentValidation
}

export class DoctorDocumentValidationService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  createDoctorDocumentValidation(
    params: CreateDoctorDocumentValidation.Params
  ): Promise<CreateDoctorDocumentValidation.Model> {
    return this.services.createDoctorDocumentValidation.create(params)
  }

  deleteDoctorDocumentValidation(
    params: DeleteDoctorDocumentValidation.Params
  ): Promise<DeleteDoctorDocumentValidation.Model> {
    return this.services.deleteDoctorDocumentValidation.delete(params)
  }

  updateDoctorDocumentValidation(
    params: UpdateDoctorDocumentValidation.Params
  ): Promise<UpdateDoctorDocumentValidation.Model> {
    return this.services.updateDoctorDocumentValidation.update(params)
  }
}
