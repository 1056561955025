import { LoadPatientSurgery } from 'domain/usecases/patient/load-patient-surgery'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteLoadPatientSurgery implements LoadPatientSurgery {
  constructor(private readonly patientRepository: PatientRepository) {}

  load(params?: LoadPatientSurgery.Params): Promise<LoadPatientSurgery.Model> {
    return this.patientRepository.loadPatientSurgery(params)
  }
}

export type LoadPatientSurgeryModel = LoadPatientSurgery.Model
