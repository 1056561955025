import { AuthorizationCheck } from 'domain/usecases/authorization/authorization'
import { AuthorizationRepository } from 'repository/interfaces/authorization-repository'

export class RemoteAuthorizationCheck implements AuthorizationCheck {
  constructor(
    private readonly authorizationRepository: AuthorizationRepository
  ) {}

  async check(
    data: AuthorizationCheck.Data,
    params: AuthorizationCheck.Params
  ): Promise<AuthorizationCheck.Model> {
    return await this.authorizationRepository.authorizationCheck(data, params)
  }
}
export type RemoteAuthorizationCheckModel = AuthorizationCheck.Model
