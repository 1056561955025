export function openPhoneApp(
  type: 'phone' | 'whatsApp',
  phoneNumber: string,
  message?: string
): void {
  const link = document.createElement('a')

  const href =
    type === 'phone' ? `tel:${phoneNumber}` : `https://wa.me/${phoneNumber}`

  const uriMessage = message && encodeURI(message)

  const finalHref = uriMessage ? `${href}?text=${uriMessage}` : href

  link.href = finalHref
  link.target = '_blank'
  link.rel = 'noreferrer'
  link.click()
}
