import React, { InputHTMLAttributes, useState } from 'react'
import { ReactComponent as ShowIcon } from 'presentation/assets/icons/eye.svg'
import * as S from './styles'

export type PasswordFieldProps = {
  onInputChange?: (value: string) => void
  label?: string
  mask?: (string | RegExp)[]
  initialValue?: string
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  error?: string
} & InputHTMLAttributes<HTMLInputElement>

const PasswordField = ({
  label,
  name,
  initialValue = '',
  error,
  iconPosition = 'right',
  disabled = false,
  onInputChange,
  style,
  ...props
}: PasswordFieldProps) => {
  const [value, setValue] = useState(initialValue)
  const [type, setType] = useState<'password' | 'text'>('password')

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    setValue(newValue)

    !!onInputChange && onInputChange(newValue)
  }

  const toggle = () => {
    setType(type === 'password' ? 'text' : 'password')
  }

  return (
    <S.Wrapper style={style} disabled={disabled} error={!!error}>
      {!!label && <S.Label htmlFor={name}>{label}</S.Label>}
      <S.InputWrapper>
        <S.Icon role="icon" onClick={toggle} iconPosition={iconPosition}>
          <ShowIcon />
        </S.Icon>
        <S.Input
          role="textbox"
          type={type}
          onChange={onChange}
          value={value}
          disabled={disabled}
          name={name}
          {...(label ? { id: name } : {})}
          {...props}
        />
      </S.InputWrapper>
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  )
}

export default PasswordField
