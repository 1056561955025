import { UpdateProfilePic } from 'domain/usecases/user/update-profile-pic'
import { UserRepository } from 'repository/repositories/user/user-repository'

export class RemoteUpdateProfilePic implements UpdateProfilePic {
  constructor(private readonly userRepository: UserRepository) {}

  update(params: UpdateProfilePic.Params): Promise<void> {
    return this.userRepository.updateProfilePic(params)
  }
}

export type UpdateProfilePicModel = UpdateProfilePic.Model
