import { LoadCollaborator } from 'domain/usecases/collaborator/load-collaborator'
import { CollaboratorRepository } from 'repository/repositories/collaborator/collaborator-repository'

export class RemoteLoadCollaborator implements LoadCollaborator {
  constructor(
    private readonly collaboratorRepository: CollaboratorRepository
  ) {}

  load(params: LoadCollaborator.Params): Promise<LoadCollaborator.Model> {
    return this.collaboratorRepository.load(params)
  }
}

export type LoadCollaboratorModel = LoadCollaborator.Model
