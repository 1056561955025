import React, { ChangeEvent } from 'react'
import { InputHTMLAttributes } from 'react'
import * as S from './styles'

type RadioValue = any

export type RadioProps = {
  onCheck?: (value?: RadioValue) => void
  label?: string | React.ReactElement
  labelColor?: 'white' | 'black'
  labelFor?: string
  value?: RadioValue
  showBackground?: boolean
  color?: 'secondary' | 'primary'
  customLabelComponent?: React.ReactElement
} & InputHTMLAttributes<HTMLInputElement>

const Radio = ({
  label,
  onCheck,
  labelFor = '',
  value,
  showBackground = true,
  color = 'secondary',
  customLabelComponent,
  ...props
}: RadioProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    !!onCheck && onCheck(e.target.value)
  }

  return (
    <S.Wrapper isChecked={!!props.checked} showBackground={showBackground}>
      <S.Input
        id={labelFor}
        type="radio"
        value={value}
        onChange={onChange}
        inputColor={color}
        {...props}
      />
      {!!label && <S.Label htmlFor={labelFor}>{label}</S.Label>}
      {customLabelComponent ? customLabelComponent : null}
    </S.Wrapper>
  )
}

export default Radio
