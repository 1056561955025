export const respiratory = ['Asma', 'Pneumonia prévia']

export const immunosuppression = [
  'Uso de corticosteróides',
  'SIDA',
  'TMO autólogo',
  'TMO alogênico',
  'Quimioterapia',
  'Radioterapia',
  'Transplantes de órgãos sólidos'
]

const cardiovascular = [
  'Angina',
  'Hipertensão Arterial',
  'Trombose venosa profunda',
  'Dça. Arterial periférica',
  'IAM prévio',
  'Fibrilação atrial crônica',
  'Insuficiência cardíaca',
  'Outras Arritmias cardíacas'
]

const neurologicalAndPsychiatric = [
  'Alcoolismo',
  'Demência',
  'AVC sem sequela',
  'AVC com sequela',
  'Doença psiquiátrica',
  'Tabagismo'
]

export const general = [
  'Hipotireodismo',
  'Insuficiência hepática',
  'Doenças reumáticas',
  'Bronquite crônica',
  'Enfisema pulmonar',
  'Diabetes',
  'Câncer',
  'Doenças renais Crônicas'
]

export const diseases = {
  respiratory,
  immunosuppression,
  cardiovascular,
  neurologicalAndPsychiatric,
  general
}
