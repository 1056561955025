import { IApiRepository } from 'service/protocols/api/api-repository'
import { SurgeryRepository as ISurgeryRepository } from 'repository/interfaces/surgery-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { LoadSurgeryDetails } from 'domain/usecases/surgery/load-surgery-details'
import {
  loadPainAlerts,
  loadSurgeryDetailsQuery
} from 'repository/graphql/queries'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { LoadPainAlerts } from 'domain/usecases/surgery/load-pain-alerts'

export class SurgeryRepository implements ISurgeryRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async loadPainAlerts(
    params?: LoadPainAlerts.Params
  ): Promise<LoadPainAlerts.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPainAlerts.Model>

    const query = loadPainAlerts(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadPainAlerts.Model
    }
  }

  async loadDetails(
    params: LoadSurgeryDetails.Params
  ): Promise<LoadSurgeryDetails.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgeryDetails.Model>

    const query = loadSurgeryDetailsQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.surgeryId, 'surgeryId')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSurgeryDetails.Model
    }
  }
}
