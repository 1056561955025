import React from 'react'

export default function HamburguerIcon() {
  return (
    <svg
      role="img"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 16H0V13.3333H24V16ZM24 9.33333H0V6.66667H24V9.33333ZM24 2.66667H0V0H24V2.66667Z"
        fill="#A4A4A4"
      />
    </svg>
  )
}
