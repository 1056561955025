import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  box-shadow: 2px 2px 8px -4px rgba(0, 0, 0, 0.1);
  padding: 21px;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  background-color: ${({ theme }) => theme.colors.white};
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  p {
    margin-left: 6px;
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const PatientInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.font.sizes.xxsmall};

  strong {
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.colors.primary};
  }

  label {
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.colors.gray};
  }

  & + & {
    margin-top: 4px;
  }
`

export const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const VitalData = styled.div`
  h5 {
    margin-top: 27px;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
  }

  p {
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.lightGray};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
  }
`

export const VitalDataIndicators = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 370px) {
    grid-template-columns: repeat(2, 1fr);
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-size: ${({ theme }) => theme.font.sizes.xxsmall};
      font-weight: ${({ theme }) => theme.font.semiBold};
      color: ${({ theme }) => theme.colors.lightGray};
    }
    span {
      font-size: ${({ theme }) => theme.font.sizes.xlarge};
      font-weight: ${({ theme }) => theme.font.bold};
    }

    &:nth-child(3) {
      justify-self: flex-end;
      @media (max-width: 370px) {
        justify-self: center;
        grid-column: span 2;
        text-align: center;
      }
    }
  }
`
