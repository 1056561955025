import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    h1 {
      text-align: center;
      font-size: ${theme.font.sizes.large};
      color: ${theme.colors.gray};

      span {
        color: ${theme.colors.secondary};
      }
    }
    p {
      text-align: center;
      margin-top: 15px;
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.gray};
      font-weight: ${theme.font.semiBold};
      span {
        font-weight: ${theme.font.black};
      }
    }
    button {
      margin-bottom: 5%;
    }
  `}
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`

export const Header = styled.header`
  justify-self: flex-start;
  align-self: flex-start;
`
