import React from 'react'
import MuiTooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import { Zoom, TooltipProps } from '@material-ui/core'
import { ReactComponent as CloseIcon } from 'presentation/assets/icons/bold-close.svg'
import * as S from './styles'

const ClickableTooltip = withStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      width: '90vw',
      maxWidth: '356px',
      padding: '10px'
    },
    arrow: {
      color: theme.palette.common.white
    }
  }
})(MuiTooltip)

type Props = {
  children: React.ReactElement
  text: React.ReactElement
  title: string
} & Partial<TooltipProps>

export function HelpTooltip({ children, text, title, ...props }: Props) {
  const quit = (e: React.MouseEvent) => {
    if (props.onClose) {
      props.onClose(e)
    }
  }
  return (
    <>
      <ClickableTooltip
        style={{ cursor: 'pointer' }}
        TransitionComponent={Zoom}
        PopperProps={{
          disablePortal: true
        }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        placement="top"
        {...props}
        interactive
        title={
          <>
            <S.HelpHeader>
              <label> {title} </label>
              <CloseIcon onClick={quit} data-testid="on-close-icon" />
            </S.HelpHeader>
            <hr />
            <S.HelpBody>{text}</S.HelpBody>
          </>
        }
      >
        {children}
      </ClickableTooltip>
      {props.open && (
        <S.Backdrop onClick={quit} data-testid="on-close-back-drop" />
      )}
    </>
  )
}
