import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ReligionService } from 'presentation/contexts/services/religion-service'
import { ApiRepository } from 'repository/api-repository'
import { ReligionRepository } from 'repository/repositories/religion/religion-repository'
import { RemoteLoadReligions } from 'service/usecases/load-religions/remote-load-religions'

export default function makeReligionService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )
  const religionRepository = new ReligionRepository(apiRepository)
  const loadReligions = new RemoteLoadReligions(religionRepository)

  return new ReligionService({
    loadReligions
  })
}
