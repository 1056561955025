import { GetStorage } from 'service/protocols/cache/get-storage'
import { SetStorage } from 'service/protocols/cache/set-storage'

export class LocalStorageAdapter implements SetStorage, GetStorage {
  set(key: string, value?: Record<any, any>): void {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.removeItem(key)
    }
  }

  get(key: string): any {
    return JSON.parse(localStorage.getItem(key)!)
  }
}
