import theme from 'presentation/styles/theme'
import styled, { css } from 'styled-components'

type ChipProps = {
  color?: keyof typeof theme.colors
  fontSize?: keyof typeof theme.font.sizes
}

export const ChipLabel = styled.label<ChipProps>`
  ${({ theme, color, fontSize }) => css`
    padding: 0 10px 0 10px;
    border-radius: 34px;
    border: 1px solid ${color ? theme.colors[color] : theme.colors.primary};
    font-size: ${fontSize
      ? theme.font.sizes[fontSize]
      : theme.font.sizes.xxsmall};
    font-weight: ${theme.font.semiBold};
    color: ${color ? theme.colors[color] : theme.colors.primary};
    background-color: ${color ? theme.colors[color] : theme.colors.primary}20;
    cursor: pointer;
  `}
`

export const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  label:not(:last-child) {
    margin-right: 12px;
  }
`
