import { AccompanyingRepository } from 'repository/interfaces/accompanying-repository'
import { DeleteAccompanying } from 'domain/usecases/accompanying/delete-accompanying'

export class RemoteDeleteAccompanying implements DeleteAccompanying {
  constructor(
    private readonly accompanyingRepository: AccompanyingRepository
  ) {}

  delete(params: DeleteAccompanying.Params): Promise<DeleteAccompanying.Model> {
    return this.accompanyingRepository.deleteAccompanying(params)
  }
}
