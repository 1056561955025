import { makeAutoObservable } from 'mobx'

export class Menu {
  constructor() {
    makeAutoObservable(this)
  }

  isOpen = false

  open() {
    this.isOpen = true
  }

  close() {
    this.isOpen = false
  }
}

export default Menu
