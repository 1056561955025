import { GetPatientNextAttendances } from 'domain/usecases/patient/get-patient-next-attendances'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'

export class RemoteGetPatientNextAttendances
  implements GetPatientNextAttendances
{
  constructor(private readonly patientRepository: PatientRepository) {}

  load(
    params: GetPatientNextAttendances.Params
  ): Promise<GetPatientNextAttendances.Model> {
    return this.patientRepository.getPatientNextAttendances(params)
  }
}

export type GetPatientNextAttendancesModel = GetPatientNextAttendances.Model
