import { LoadFavoriteSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-surgical-orders'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadFavoriteSurgicalOrders
  implements LoadFavoriteSurgicalOrders
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  load(
    params: LoadFavoriteSurgicalOrders.Params
  ): Promise<LoadFavoriteSurgicalOrders.Model> {
    return this.surgicalOrderRepository.loadFavoriteSurgicalOrders(params)
  }
}
