import styled, { css } from 'styled-components'
import { CheckboxProps } from '.'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 18px;
    min-width: 18px;
    height: 18px;
    border: 2px solid ${theme.colors.primary};
    border-radius: 4px;
    transition: background border ${theme.transition.fast};
    position: relative;
    outline: none;

    &:after {
      content: '';
      width: 0;
      height: 0;
      transition: 200ms;
      transition-property: height, width;
    }

    &:focus {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200%;
        height: 200%;
        background: ${theme.colors.primary};
        border-radius: 50%;
        opacity: 0.4;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
      }
    }

    &:before {
      content: '';
      width: 6px;
      height: 9px;
      border: 2px solid ${theme.colors.white};
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
      position: absolute;
      top: 1px;
      opacity: 0;
      transition: ${theme.transition.fast};
      z-index: 2;
    }

    &:hover {
      border-color: ${theme.colors.gray};
      transition: ${theme.transition.fast};
    }

    &:checked {
      border-color: ${theme.colors.primary};
      background: ${theme.colors.primary};
      &:before {
        //background-color: white;
        opacity: 1;
      }
    }
  `}
`

export const Label = styled.label<Pick<CheckboxProps, 'labelColor'>>`
  ${({ theme, labelColor }) => css`
    cursor: pointer;
    line-height: 18px;
    padding-left: 10px;
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors[labelColor!]};
  `}
`
