import { LoadSecretarySurgicalPendenciesCount } from 'domain/usecases/secretary/load-secretary-surgical-pendencies-count'
import { SecretaryRepository } from 'repository/interfaces/secretary-repository'

export class RemoteLoadSecretarySurgicalPendenciesCount
  implements LoadSecretarySurgicalPendenciesCount
{
  constructor(private readonly secretaryRepository: SecretaryRepository) {}

  count(
    params?: LoadSecretarySurgicalPendenciesCount.Params
  ): Promise<LoadSecretarySurgicalPendenciesCount.Model> {
    return this.secretaryRepository.countSurgicalPendencies(params)
  }
}

export type LoadSecretarySurgicalPendenciesCountModel =
  LoadSecretarySurgicalPendenciesCount.Model
