import { CountryPhoneCode } from 'common/enum/country-phone-codes'

const getPhoneWithCountryCode = (
  phone: string | undefined,
  countryCode: CountryPhoneCode
) => {
  if (!phone || !countryCode) {
    return ''
  }
  if (phone.length === 13 || phone.substring(0, 2).includes(countryCode)) {
    return phone
  }
  return `${countryCode}${phone}`
}

export default getPhoneWithCountryCode
