import { LoadAddress } from 'domain/usecases/address/load-address'
import { AddressRepository } from 'repository/interfaces/address-repository'

export class RemoteLoadAddress implements LoadAddress {
  constructor(private readonly addressRepository: AddressRepository) {}

  load(): Promise<LoadAddress.Model> {
    return this.addressRepository.loadAddress()
  }
}
