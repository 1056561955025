import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadSurgicalOrderDocument
  implements LoadSurgicalOrderDocument
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}
  load(
    surgical_order_id: number | string,
    document_id: number
  ): Promise<LoadSurgicalOrderDocument.Model> {
    return this.surgicalOrderRepository.loadSurgicalOrderDocument(
      surgical_order_id,
      document_id
    )
  }
}

export type LoadSurgicalOrderDocumentModel = LoadSurgicalOrderDocument.Model
