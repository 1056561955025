const normalizeText = (text: string | undefined) => {
  try {
    if (!text || text === '') {
      return ''
    }
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  } catch (err) {
    return ''
  }
}

export default normalizeText
