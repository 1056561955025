import { AddAccompanying } from 'domain/usecases/accompanying/add-accompanying'
import { AccompanyingRepository } from 'repository/interfaces/accompanying-repository'

export class RemoteAddAccompanying implements AddAccompanying {
  constructor(
    private readonly accompanyingRepository: AccompanyingRepository
  ) {}

  add(params: AddAccompanying.Params): Promise<AddAccompanying.Model> {
    return this.accompanyingRepository.addAccompanying(params)
  }
}
