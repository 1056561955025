import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { RemoteLoadCollaborator } from 'service/usecases/load-collaborator/remote-load-collaborator'
import { CollaboratorRepository } from 'repository/repositories/collaborator/collaborator-repository'
import CollaboratorService from 'presentation/contexts/services/collaborator-service'
import { RemoteUpdateCollaborator } from 'service/usecases/update-collaborator/remote-update-collaborator'
import { RemoteSearchColaborators } from 'service/usecases/search-collaborators/remote-search-collaborators'

export default function makeCollaboratorService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const collaboratorRepository = new CollaboratorRepository(apiRepository)

  const loadCollaborator = new RemoteLoadCollaborator(collaboratorRepository)
  const updateCollaborator = new RemoteUpdateCollaborator(
    collaboratorRepository
  )
  const searchCollaborators = new RemoteSearchColaborators(
    collaboratorRepository
  )

  return new CollaboratorService({
    loadCollaborator,
    updateCollaborator,
    searchCollaborators
  })
}
