export default function downloadFileFromBlob(
  file: Blob,
  contentType: string,
  fileName: string
) {
  try {
    const fileBlob = new Blob([file], { type: contentType })
    const fileURL = URL.createObjectURL(fileBlob)
    const link = document.createElement('a')
    link.href = fileURL
    link.download = fileName
    link.click()
  } catch (err) {
    return undefined
  }
}
