import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    label {
      color: ${theme.colors.primary};
      font-weight: ${theme.font.normal};
      font-size: ${theme.font.sizes.xsmall};
      padding-left: 8px;
      cursor: pointer;
    }
  `}
`
