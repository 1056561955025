import React from 'react'

import * as S from './styles'

export type LabelBoxProps = {
  text: React.ReactNode
  children: React.ReactNode
  columns: number
}

export default function LabelBox({ text, children, columns }: LabelBoxProps) {
  return (
    <S.Wrapper>
      <S.Label cols={columns}>{text}</S.Label>
      <S.Box cols={columns}>{children}</S.Box>
    </S.Wrapper>
  )
}
