import { GetSecretaryByCpf } from 'domain/usecases/secretary/get-secretary-by-cpf'
import { SecretaryRepository } from 'repository/interfaces/secretary-repository'

export class RemoteGetSecretaryByCpf implements GetSecretaryByCpf {
  constructor(private readonly secretaryRepository: SecretaryRepository) {}

  execute(params: GetSecretaryByCpf.Params): Promise<GetSecretaryByCpf.Model> {
    return this.secretaryRepository.getSecretaryByCpf(params)
  }
}
