import { AddAccompanying } from 'domain/usecases/accompanying/add-accompanying'
import { AccompanyingRepository as IAccompanyingRepository } from 'repository/interfaces/accompanying-repository'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import {
  addAccompanyingMutation,
  deleteAccompanyingMutation,
  updateAccompanyingMutation
} from 'repository/graphql/mutations'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { DeleteAccompanying } from 'domain/usecases/accompanying/delete-accompanying'
import { UpdateAccompanying } from 'domain/usecases/accompanying/update-accompanying'

export class AccompanyingRepository implements IAccompanyingRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async addAccompanying(
    params: AddAccompanying.Params
  ): Promise<AddAccompanying.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddAccompanying.Model>

    const query = addAccompanyingMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddAccompanying.Model
    }
  }

  async deleteAccompanying(
    params: DeleteAccompanying.Params
  ): Promise<DeleteAccompanying.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<DeleteAccompanying.Model>

    const query = deleteAccompanyingMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(
          params.accompanists_ids,
          'accompanists_ids'
        )
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok || !httpResponse.body) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DeleteAccompanying.Model
    }
  }

  async updateAccompanying(
    params: UpdateAccompanying.Params
  ): Promise<UpdateAccompanying.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateAccompanying.Model>

    const query = updateAccompanyingMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateAccompanying.Model
    }
  }
}
