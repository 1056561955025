import styled, { css, DefaultTheme } from 'styled-components'

type DotProps = {
  active: boolean
  margin: boolean
}

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    transition: ${theme.transition.default};
  `}
`

export const Dot = styled.div<DotProps>`
  ${({ theme, active, margin }) => css`
    position: relative;
    width: 8px;
    height: 8px;
    background-color: ${theme.colors.lightGray};
    border-radius: ${theme.border.radius.rounded};
    cursor: pointer;
    ${!!active && wrapperModifiers.active(theme)}
    ${!margin && wrapperModifiers.done(theme)}
    ${!!margin && wrapperModifiers.margin()}
  `}
`

export const HorizontalLine = styled.hr`
  ${({ theme }) => css`
    width: 36px;
    /* height: 1px; */
    color: ${theme.colors.secondary};
    border: 1px solid ${theme.colors.secondary};
    background-color: ${theme.colors.secondary};
  `}
`

const wrapperModifiers = {
  active: (theme: DefaultTheme) => css`
    &::after {
      content: '';
      position: absolute;
      top: -3px;
      left: -3px;
      width: 14px;
      height: 14px;
      border: 1px solid ${theme.colors.secondary};
      border-radius: ${theme.border.radius.rounded};
      display: block;
    }
  `,
  margin: () => css`
    margin-left: 36px;
  `,
  done: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.secondary};
  `
}
