import { LoadPatientByCpf } from 'domain/usecases/patient/load-patient-by-cpf'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteLoadPatientByCpf implements LoadPatientByCpf {
  constructor(private readonly patientRepository: PatientRepository) {}

  load(
    cpf: string,
    params?: LoadPatientByCpf.Params
  ): Promise<LoadPatientByCpf.Model> {
    return this.patientRepository.loadPatientByCpf(cpf, params)
  }
}

export type LoadPatientByCpfModel = LoadPatientByCpf.Model
