import { DeleteSurgicalOrderSolicitation } from 'domain/usecases/surgical-order/delete-surgical-order-solicitation'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteDeleteSurgicalOrderSolicitation
  implements DeleteSurgicalOrderSolicitation
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  delete(
    params: DeleteSurgicalOrderSolicitation.Params
  ): Promise<DeleteSurgicalOrderSolicitation.Model> {
    return this.surgicalOrderRepository.deleteSurgicalOrderSolicitation(params)
  }
}
