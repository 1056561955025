import { AnswerSurgicalPendency } from 'domain/usecases/surgical-pendency/answer-surgical-pendency'
import { SurgicalPendencyRepository } from 'repository/interfaces/surgical-pendency-repository'

export class RemoteAnswerSurgicalPendency implements AnswerSurgicalPendency {
  constructor(
    private readonly secretaryRepository: SurgicalPendencyRepository
  ) {}

  answer(
    params: AnswerSurgicalPendency.Params
  ): Promise<AnswerSurgicalPendency.Model> {
    return this.secretaryRepository.answerSurgicalPendency(params)
  }
}
