import { UpdateCollaborator } from 'domain/usecases/collaborator/update-collaborator'

import { CollaboratorRepository } from 'repository/repositories/collaborator/collaborator-repository'

export class RemoteUpdateCollaborator implements UpdateCollaborator {
  constructor(
    private readonly collaboratorRepository: CollaboratorRepository
  ) {}

  update(params: UpdateCollaborator.Params): Promise<UpdateCollaborator.Model> {
    return this.collaboratorRepository.updateCollaborator(params)
  }
}

export type UpdateCollaboratorModel = UpdateCollaborator.Model
