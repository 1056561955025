import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    justify-self: flex-start;
    align-self: flex-start;
    margin-top: 10px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const Content = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    & > p {
      align-self: center;
      justify-self: center;
      margin-top: 8px;
      margin-bottom: 8px;
      color: ${theme.colors.lightGray};
      font-weight: ${theme.font.semiBold};
    }
  `}
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  button {
    margin-top: 10px;
  }
`
