import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { SurgeryCenterRepository } from 'repository/interfaces/surgery-center-repository'

export class RemoteLoadSurgeryCenter implements LoadSurgeryCenter {
  constructor(
    private readonly surgeryCenterRepository: SurgeryCenterRepository
  ) {
    this.surgeryCenterRepository = surgeryCenterRepository
  }

  load(params: LoadSurgeryCenter.Params): Promise<LoadSurgeryCenter.Model> {
    return this.surgeryCenterRepository.loadSurgeryCenter(params)
  }
}
