import { Signup } from 'domain/usecases/authentication/signup'
import { AuthenticationRepository } from 'repository/interfaces/authentication-repository'

export class RemoteSignup implements Signup {
  constructor(
    private readonly authenticationRepository: AuthenticationRepository
  ) {}
  signup(params: Signup.Params): Promise<Signup.Model> {
    return this.authenticationRepository.signup(params)
  }
}

export type SignupModel = Signup.Model
