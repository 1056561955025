import { PatientDocument } from 'common/enum/patient-document'

export const documentTypesLocation = [
  {
    label: 'carteira do convenio',
    value: PatientDocument.HEALTH_CARD
  },
  {
    label: 'carteira de identidade',
    value: PatientDocument.IDENTITY_CARD
  },
  {
    label: 'carteira do convenio frente',
    value: PatientDocument.HEALTH_CARD_FRONT
  },
  {
    label: 'carteira do convenio verso',
    value: PatientDocument.HEALTH_CARD_BACK
  },
  {
    label: 'relatorio medico',
    value: PatientDocument.MEDICAL_REPORT
  },
  {
    label: 'carteira de identidade frente',
    value: PatientDocument.IDENTITY_CARD_FRONT
  },
  {
    label: 'carteira de identidade verso',
    value: PatientDocument.IDENTITY_CARD_BACK
  },
  {
    label: 'acordo de servico',
    value: PatientDocument.SERVICE_AGREEMENT
  },
  {
    label: 'autorizacao',
    value: PatientDocument.AUTHORIZATION
  },
  {
    label: 'planejamento cirurgico',
    value: PatientDocument.SURGICAL_PLANNING
  },
  {
    label: 'consentimento cirurgico',
    value: PatientDocument.SURGICAL_CONSENT
  },
  {
    label: 'consentimento anestesico',
    value: PatientDocument.ANESTHETIC_CONSENT
  },
  {
    label: 'consentimento pre anestesico',
    value: PatientDocument.PRE_ANESTHETIC_CONSENT
  },
  {
    label: 'avalicao pre anestesica',
    value: PatientDocument.PRE_ANESTHETIC_EVALUATION
  },
  {
    label: 'consentimento de hemoterapia',
    value: PatientDocument.HEMOTHERAPY_CONSENT
  },
  {
    label: 'risco cirurgico',
    value: PatientDocument.SURGICAL_RISK
  },
  {
    label: 'pedido cirurgico',
    value: PatientDocument.SURGICAL_ORDER
  },
  {
    label: 'pedido medico',
    value: PatientDocument.MEDICAL_ORDER
  },
  {
    label: 'laudo de exame',
    value: PatientDocument.EXAM_REPORT
  },
  {
    label: 'laudo de imagem',
    value: PatientDocument.IMAGE_REPORT
  },
  {
    label: 'termo de consentimento',
    value: PatientDocument.CONSENT_TERM
  },
  {
    label: 'todas as guias',
    value: PatientDocument.ALL_GUIDANCES
  },
  {
    label: 'guia sadt',
    value: PatientDocument.GUIDE_SPSADT
  },
  {
    label: 'guia solicitacao de internacao',
    value: PatientDocument.GUIDE_HOSPITALIZATION_REQUEST
  },
  {
    label: 'guia de opme',
    value: PatientDocument.GUIDE_OPME
  },
  {
    label: 'planejamento cirurgico',
    value: PatientDocument.GUIDE_SURGICAL_PLANNING
  },
  {
    label: 'guia de pedido cirurgico',
    value: PatientDocument.GUIDE_SURGICAL_ORDER
  }
]

export const documentTypesLocationWithAccents = [
  {
    label: 'Carteira do convênio',
    value: PatientDocument.HEALTH_CARD
  },
  {
    label: 'Carteira de identidade',
    value: PatientDocument.IDENTITY_CARD
  },
  {
    label: 'Carteira do convênio - Frente',
    value: PatientDocument.HEALTH_CARD_FRONT
  },
  {
    label: 'Carteira do convênio - Verso',
    value: PatientDocument.HEALTH_CARD_BACK
  },
  {
    label: 'Relatório medico',
    value: PatientDocument.MEDICAL_REPORT
  },
  {
    label: 'Carteira de identidade - Frente',
    value: PatientDocument.IDENTITY_CARD_FRONT
  },
  {
    label: 'Carteira de identidade - Verso',
    value: PatientDocument.IDENTITY_CARD_BACK
  },
  {
    label: 'Ccordo de serviço',
    value: PatientDocument.SERVICE_AGREEMENT
  },
  {
    label: 'Autorização',
    value: PatientDocument.AUTHORIZATION
  },
  {
    label: 'Planejamento cirúrgico',
    value: PatientDocument.SURGICAL_PLANNING
  },
  {
    label: 'Consentimento cirúrgico',
    value: PatientDocument.SURGICAL_CONSENT
  },
  {
    label: 'Consentimento anestésico',
    value: PatientDocument.ANESTHETIC_CONSENT
  },
  {
    label: 'Consentimento pre anestésico',
    value: PatientDocument.PRE_ANESTHETIC_CONSENT
  },
  {
    label: 'Avaliação pre anestésica',
    value: PatientDocument.PRE_ANESTHETIC_EVALUATION
  },
  {
    label: 'Consentimento de hemoterapia',
    value: PatientDocument.HEMOTHERAPY_CONSENT
  },
  {
    label: 'Risco cirúrgico',
    value: PatientDocument.SURGICAL_RISK
  },
  {
    label: 'Pedido cirúrgico',
    value: PatientDocument.SURGICAL_ORDER
  },
  {
    label: 'Pedido médico',
    value: PatientDocument.MEDICAL_ORDER
  },
  {
    label: 'Laudo de exame',
    value: PatientDocument.EXAM_REPORT
  },
  {
    label: 'Laudo de imagem',
    value: PatientDocument.IMAGE_REPORT
  },
  {
    label: 'Termo de consentimento',
    value: PatientDocument.CONSENT_TERM
  },
  {
    label: 'Todas as guias',
    value: PatientDocument.ALL_GUIDANCES
  },
  {
    label: 'Guia SADT',
    value: PatientDocument.GUIDE_SPSADT
  },
  {
    label: 'Guia Solicitação de Internação',
    value: PatientDocument.GUIDE_HOSPITALIZATION_REQUEST
  },
  {
    label: 'Guia de OPME',
    value: PatientDocument.GUIDE_OPME
  },
  {
    label: 'Planejamento Cirúrgico',
    value: PatientDocument.GUIDE_SURGICAL_PLANNING
  },
  {
    label: 'Guia de Pedido Cirúrgico',
    value: PatientDocument.GUIDE_SURGICAL_ORDER
  }
]

export const getDocumentsLabelLocationByEnum = (
  documentTypeEnum: string,
  withAccents?: boolean
) => {
  const source = withAccents
    ? documentTypesLocationWithAccents
    : documentTypesLocation
  const documentType = source.find((e) => e.value === documentTypeEnum)
  return documentType ? documentType.label : ''
}
