import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0px 24px;
  padding-bottom: 10px;
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const Content = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CodeInputWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CodeInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  & > div {
    width: max(200px, 25%);
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  input {
    width: 100%;
    text-align: center;
    padding: 0;
  }
`

export const Controls = styled.div`
  ${({ theme }) => css`
    top: 10px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: ${theme.layers.overlay};
  `}
`
