import { LoadCitiesFromDistrict } from 'domain/usecases/address/load-cities-from-district'
import { AddressRepository } from 'repository/interfaces/address-repository'

export class RemoteLoadCitiesFromDistrict implements LoadCitiesFromDistrict {
  constructor(private readonly addressRepository: AddressRepository) {}

  load(
    params: LoadCitiesFromDistrict.Params
  ): Promise<LoadCitiesFromDistrict.Model> {
    return this.addressRepository.loadCitiesFromDistrict(params)
  }
}
