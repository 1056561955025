import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;

  h1 {
    p {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  //align-items: center;
  flex-direction: column;
  overflow-y: auto;
`

export const ConfirmationModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    p {
      margin-top: 12px;
      margin-bottom: 24px;
    }
    strong {
      color: ${theme.colors.primary};
    }
  `}
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`
