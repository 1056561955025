import { LinkSecretary } from 'domain/usecases/doctor/link-secretary'
import { DoctorRepository } from 'repository/repositories/doctor/doctor-repository'

export class RemoteLinkSecretary implements LinkSecretary {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  async execute(params: LinkSecretary.Params): Promise<LinkSecretary.Model> {
    return this.doctorRepository.linkSecretary(params)
  }
}
