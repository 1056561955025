import { makeAutoObservable } from 'mobx'
import { LoadSpecialties } from 'domain/usecases/specialties/load-specialties'

type Services = {
  loadSpecialties: LoadSpecialties
}

export class SpecialtyService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadSpecialties(
    params: LoadSpecialties.Params
  ): Promise<LoadSpecialties.Model> {
    return this.services.loadSpecialties.load(params)
  }
}

export default SpecialtyService
