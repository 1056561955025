import { GetRequiredDoctorDocuments } from 'domain/usecases/doctor/get-doctor-required-documents'
import { DoctorDocument } from 'common/enum/doctor-document'
import { DoctorSpecialty } from 'domain/entities/doctor-specialty-model'
import { ActingSectors } from 'common/enum/acting-sectors'

export class GetRequiredDoctorDocumentsImpl
  implements GetRequiredDoctorDocuments
{
  execute(
    doctor: GetRequiredDoctorDocuments.Params
  ): GetRequiredDoctorDocuments.Model {
    const documentsTypes: DoctorDocument[] = []
    const { specialties, sectors } = doctor
    if (specialties) {
      documentsTypes.push(
        ...this.getRequiredDocumentsBySpecialties(specialties)
      )
    }
    if (sectors) {
      documentsTypes.push(
        ...this.getRequiredDocumentsBySectors(sectors as ActingSectors[])
      )
    }

    return [...new Set(documentsTypes)]
  }

  private getRequiredDocumentsBySpecialties(
    specialties: DoctorSpecialty[]
  ): DoctorDocument[] {
    const documentsTypes: DoctorDocument[] = []

    for (const specialty of specialties) {
      documentsTypes.push(
        ...this.getExternalRequiredDocumentsBySpecialty(specialty)
      )
    }

    return documentsTypes
  }

  private getExternalRequiredDocumentsBySpecialty(
    specialty: DoctorSpecialty
  ): DoctorDocument[] {
    const documentsTypes: DoctorDocument[] = []
    if (specialty.name?.match?.(/ANESTE/g)) {
      documentsTypes.push(DoctorDocument.ACLS)
      documentsTypes.push(DoctorDocument.PALS)
    }
    return documentsTypes
  }

  private getRequiredDocumentsBySectors(
    sectors: ActingSectors[]
  ): DoctorDocument[] {
    const documentsTypes: DoctorDocument[] = []
    for (const sector of sectors) {
      documentsTypes.push(...this.getExternalRequiredDocumentsBySector(sector))
    }
    return documentsTypes
  }

  private getExternalRequiredDocumentsBySector(
    sector: ActingSectors
  ): DoctorDocument[] {
    const documentsTypes: DoctorDocument[] = []
    switch (sector) {
      case ActingSectors.PEDIATRIC_INTENSIVE_CARE_UNIT:
        documentsTypes.push(DoctorDocument.PALS)
        break
      case ActingSectors.NEONATAL_INTENSIVE_CARE_UNIT:
        documentsTypes.push(DoctorDocument.NALS)
        break
      case ActingSectors.INTENSIVE_TREATMENT_CENTER:
      case ActingSectors.INTENSIVE_TREATMENT_CENTER_CORONARY:
      case ActingSectors.EMERGENCY_ROOM:
        documentsTypes.push(DoctorDocument.ACLS)
        break
      default:
        break
    }
    return documentsTypes
  }
}
