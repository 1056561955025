import { InvitePatient } from 'domain/usecases/patient/invite-patient'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteInvitePatient implements InvitePatient {
  constructor(private readonly patientRepository: PatientRepository) {}

  update(params: InvitePatient.Params): Promise<InvitePatient.Model> {
    return this.patientRepository.invitePatient(params)
  }
}

export type InvitePatientModel = InvitePatient.Model
