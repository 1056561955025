import { InviteNewBornPatient } from 'domain/usecases/patient/invite-newborn-patient'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteInviteNewBornPatient implements InviteNewBornPatient {
  constructor(private readonly patientRepository: PatientRepository) {}

  add(
    params: InviteNewBornPatient.Params
  ): Promise<InviteNewBornPatient.Model> {
    return this.patientRepository.inviteNewBornPatient(params)
  }
}
