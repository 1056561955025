import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadCid implements LoadCid {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  load(params?: LoadCid.Params): Promise<LoadCid.Model> {
    return this.surgicalOrderRepository.loadCid(params)
  }
}

export type LoadCidModel = LoadCid.Model
