import { EligibilityCheck } from 'domain/usecases/eligibility/eligibility-check'
import { makeAutoObservable } from 'mobx'

type Services = {
  eligibilityCheck: EligibilityCheck
}

export class EligibilityService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async eligibilityCheck(
    data: EligibilityCheck.Data,
    params: EligibilityCheck.Params
  ): Promise<EligibilityCheck.Model> {
    return this.services.eligibilityCheck.check(data, params)
  }
}

export default EligibilityService
