import { Authorization } from 'domain/entities/authorization-model'
import { AuthorizationCheck } from 'domain/usecases/authorization/authorization'
import { checkAuthorization } from 'repository/graphql/queries'
import { AuthorizationRepository as IAuthorizationRepository } from 'repository/interfaces/authorization-repository'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'

export class AuthorizationRepository implements IAuthorizationRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async authorizationCheck(
    data: AuthorizationCheck.Data,
    params: AuthorizationCheck.Params
  ): Promise<AuthorizationCheck.Model> {
    const apiRepository = this.apiRepository as IApiRepository<Authorization>

    const query = checkAuthorization(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(data, 'data')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as Authorization
    }
  }
}
