import { LoadUserByInviteToken } from 'domain/usecases/authentication/get-user-by-invite-token'
import { AuthenticationRepository } from 'repository/interfaces/authentication-repository'

export class RemoteLoadUserByInviteToken implements LoadUserByInviteToken {
  constructor(
    private readonly authenticationRepository: AuthenticationRepository
  ) {}

  load(
    token: string,
    params?: LoadUserByInviteToken.Params
  ): Promise<LoadUserByInviteToken.Model> {
    return this.authenticationRepository.loadUserByInviteToken(token, params)
  }
}

export type LoadUserByInviteTokenModel = LoadUserByInviteToken.Model
