import { DownloadPatientDocument } from 'domain/usecases/patient/download-patient-document'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteDownloadPatientDocument implements DownloadPatientDocument {
  constructor(private readonly patientRepository: PatientRepository) {}

  load(document_id: number): Promise<LoadSurgicalOrderDocument.Model> {
    return this.patientRepository.downloadPatientDocument(document_id)
  }
}
