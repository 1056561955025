import React, { ReactElement } from 'react'
import Button from 'presentation/shared/components/Button'
import Heading from 'presentation/shared/components/Heading'
import ActualPage from 'presentation/shared/components/ActualPage'
import * as S from './styles'

type OnboardingPageProps = {
  title: string
  image: string
  body: ReactElement
  slideNext?: () => void
  showButton?: boolean
  goBackAction?: () => void
  buttonAction?: () => void
}

export default function OnboardingPage({
  title,
  image,
  body,
  slideNext,
  showButton,
  goBackAction,
  buttonAction
}: OnboardingPageProps) {
  return (
    <>
      <S.GoBackButtonContainer>
        {goBackAction && <ActualPage text={'Voltar'} onClick={goBackAction} />}
      </S.GoBackButtonContainer>

      <S.Wrapper onClick={slideNext} role={'slide-next-button'}>
        <S.ImageContainer>
          <img src={image} />
        </S.ImageContainer>

        <S.TextContainer>
          <Heading as={'h1'}>{title}</Heading>

          {body}
        </S.TextContainer>

        {showButton && buttonAction && (
          <S.ButtonWrapper>
            <Button type={'button'} onClick={buttonAction}>
              Ir para Home
            </Button>
          </S.ButtonWrapper>
        )}
      </S.Wrapper>
    </>
  )
}
