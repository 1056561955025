import React from 'react'
import * as S from './styles'
import Button, { ButtonProps } from '../../Button'

type ActionButtonProps = {
  text?: string
  show?: boolean
} & ButtonProps

export type UploadActionsProps = {
  onPrimaryButtonClick?: () => void
  onSecondaryButtonClick?: () => void
  primaryButtonProps?: ActionButtonProps
  secondaryButtonProps?: ActionButtonProps
}

export function UploadActions({
  onPrimaryButtonClick,
  primaryButtonProps,
  secondaryButtonProps,
  onSecondaryButtonClick
}: UploadActionsProps) {
  const showPrimary = primaryButtonProps?.show ?? true
  const showSecondary = secondaryButtonProps?.show ?? true
  const textPrimary = primaryButtonProps?.text ?? 'Enviar'
  const textSecondary = secondaryButtonProps?.text ?? 'Cancelar'
  return (
    <S.UploadActionsContainer>
      {showPrimary && (
        <Button onClick={onPrimaryButtonClick} {...primaryButtonProps}>
          {textPrimary}
        </Button>
      )}
      {showSecondary && (
        <Button
          variant="outlined"
          onClick={onSecondaryButtonClick}
          {...secondaryButtonProps}
        >
          {textSecondary}
        </Button>
      )}
    </S.UploadActionsContainer>
  )
}
