import { LoadHealthInsurance } from 'domain/usecases/health-insurance/load-health-insurance'
import { makeAutoObservable } from 'mobx'

type Services = {
  loadHealthInsurancePlans: LoadHealthInsurance
}

export class HealthInsurancePlansService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadHealthInsurancePlans(
    params: LoadHealthInsurance.Params
  ): Promise<LoadHealthInsurance.Model> {
    return this.services.loadHealthInsurancePlans.load(params)
  }
}

export default HealthInsurancePlansService
