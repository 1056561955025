import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  ${({ theme }) => css`
    select {
      display: block;
      width: 100%;
      max-width: 91px;
    }

    textarea {
      width: 100%;
      margin-bottom: 20px;
    }

    p {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.gray};
      text-align: center;
      line-height: 22px;
    }
  `}
`

export const Diseases = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  div {
    margin-right: 10px;
  }
`
