import React from 'react'

import * as S from './styles'

import LoadingGifIcon from 'presentation/assets/icons/loading.gif'

export default function LoadingGif() {
  return (
    <div style={{ width: '100%', display: 'flex', height: '100%' }}>
      <S.Wrapper src={LoadingGifIcon} />
    </div>
  )
}
