import { AddressRepository as IAddressRepository } from 'repository/interfaces/address-repository'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { LoadDistrictsByDescription } from 'domain/usecases/address/load-districts-by-description'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import {
  getCitiesFromDistrict,
  getDistrictsByDescription,
  loadUserAddress
} from 'repository/graphql/queries'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { LoadCitiesFromDistrict } from 'domain/usecases/address/load-cities-from-district'
import { AddAddress } from 'domain/usecases/address/add-address'
import { addAddressMutation } from 'repository/graphql/mutations'
import { LoadAddress } from 'domain/usecases/address/load-address'

export class AddressRepository implements IAddressRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async loadDistricts(
    params: LoadDistrictsByDescription.Params
  ): Promise<LoadDistrictsByDescription.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDistrictsByDescription.Model>

    const query = getDistrictsByDescription()
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.description, 'description')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDistrictsByDescription.Model
    }
  }

  async loadCitiesFromDistrict(
    params: LoadCitiesFromDistrict.Params
  ): Promise<LoadCitiesFromDistrict.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadCitiesFromDistrict.Model>

    const query = getCitiesFromDistrict()
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.uf, 'uf'),
          ...makeGraphQLVariable(params.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadCitiesFromDistrict.Model
    }
  }

  async addAddress(params: AddAddress.Params): Promise<AddAddress.Model> {
    const apiRepository = this.apiRepository as IApiRepository<AddAddress.Model>

    const query = addAddressMutation
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.data, 'data')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddAddress.Model
    }
  }

  async loadAddress(): Promise<LoadAddress.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadAddress.Model>

    const query = loadUserAddress()
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      if (httpResponse.error === 'NOT_FOUND') {
        return undefined
      }
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadAddress.Model
    }
  }
}
