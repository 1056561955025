import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadSurgicalOrder implements LoadSurgicalOrder {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  load(
    id: number,
    params?: LoadSurgicalOrder.Params
  ): Promise<LoadSurgicalOrder.Model> {
    return this.surgicalOrderRepository.loadSurgicalOrder(id, params)
  }
}

export type LoadSurgicalOrderModel = LoadSurgicalOrder.Model
