import { AddPainAlert } from 'domain/usecases/patient/add-pain-alert'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteAddPainAlert implements AddPainAlert {
  constructor(private readonly patientRepository: PatientRepository) {}

  add(params: AddPainAlert.Params): Promise<AddPainAlert.Model> {
    return this.patientRepository.addPainAlert(params)
  }
}
