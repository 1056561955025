import { LoadReligions } from 'domain/usecases/religion/load-religions'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { loadReligionsQuery } from 'repository/graphql/queries'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { ReligionRepository as IReligionRepository } from 'repository/interfaces/religion-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'

export class ReligionRepository implements IReligionRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async loadReligions(): Promise<LoadReligions.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadReligions.Model>

    const query = loadReligionsQuery
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadReligions.Model
    }
  }
}
