import styled from 'styled-components'

export const TermsWrapper = styled.div`
  max-height: 90%;
  overflow-y: scroll;
  background-color: transparent;
  margin: auto;
`

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`
