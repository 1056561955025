import { PatientRepository } from 'repository/interfaces/patient-repository'
import { CompletePatientSignup } from 'domain/usecases/patient/complete-patient-signup'

export class RemoteCompletePatientSignup implements CompletePatientSignup {
  constructor(private readonly patientRepository: PatientRepository) {}
  add(
    params: CompletePatientSignup.Params
  ): Promise<CompletePatientSignup.Model> {
    return this.patientRepository.completePatientSignup(params)
  }
}
