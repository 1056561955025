import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  h1 {
    margin-top: 10px;
    margin-bottom: 17px;
  }
`

export const DocumentsList = styled.div`
  ${({ theme }) => css`
    background-color: white;
    margin-top: 24px;
    border-radius: ${theme.border.radius.small};
    li {
      flex-direction: row-reverse;
    }

    img.icon {
      width: 28px;
    }
  `}
`
