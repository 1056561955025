import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteUploadPatientDocument implements UploadPatientDocument {
  constructor(private readonly patientRepository: PatientRepository) {}
  upload(
    params: UploadPatientDocument.Params
  ): Promise<UploadPatientDocument.Model> {
    return this.patientRepository.uploadPatientDocument(params)
  }
}

export type UploadPatientDocumentModel = UploadPatientDocument.Model
