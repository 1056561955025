import React from 'react'

import * as S from './styles'

type Props = {
  stepAmount: number
  actualStep: number
}

export const ProgressBar = ({ stepAmount = 2, actualStep = 1 }: Props) => {
  const getProgressValueByStep = (): number => {
    const PERCENTAGE_TOTAL = 100
    const percentagePerStep = PERCENTAGE_TOTAL / stepAmount
    return percentagePerStep * actualStep
  }

  return (
    <S.Wrapper>
      <S.LinearStyled value={getProgressValueByStep()} variant="determinate" />
    </S.Wrapper>
  )
}
