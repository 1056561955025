import { UploadSurgicalOrderDocument } from 'domain/usecases/surgical-order/upload-surgical-order-document'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteUploadSurgicalOrderDocument
  implements UploadSurgicalOrderDocument
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}
  upload(
    params: UploadSurgicalOrderDocument.Params
  ): Promise<UploadSurgicalOrderDocument.Model> {
    return this.surgicalOrderRepository.uploadSurgicalOrderDocument(params)
  }
}

export type UploadPatientDocumentModel = UploadSurgicalOrderDocument.Model
