import { LoadDoctorInfo } from 'domain/usecases/doctor/load-doctor-info'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadDoctorInfo implements LoadDoctorInfo {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(
    doctor_id: number,
    params?: LoadDoctorInfo.Params
  ): Promise<LoadDoctorInfo.Model> {
    return this.doctorRepository.loadDoctorInfo(doctor_id, params)
  }
}

export type LoadDoctorInfoModel = LoadDoctorInfo.Model
