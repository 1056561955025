type RenameFileOptions = {
  forceOriginalExtension?: boolean
}

export function renameFile(
  file: File,
  newFileName?: string,
  options?: RenameFileOptions
): File {
  const fileName = getFileName(file.name, newFileName, options)
  const newFile = new File([file], fileName, {
    type: file.type
  })
  return newFile
}

function getFileName(
  originalName: string,
  newFileName?: string,
  options?: RenameFileOptions
): string {
  if (!newFileName) return originalName
  const fileNameSplitted = newFileName.split('.')
  const originalFileNameSplitted = originalName.split('.')
  const originalFileExtension =
    originalFileNameSplitted.length > 1 &&
    originalFileNameSplitted[originalFileNameSplitted.length - 1]
  if (options?.forceOriginalExtension && originalFileExtension)
    return `${newFileName}.${originalFileExtension}`
  if (!originalFileExtension) return newFileName
  const extension =
    fileNameSplitted.length > 1
      ? fileNameSplitted.pop()
      : originalFileNameSplitted.pop()
  return `${fileNameSplitted.join('.')}.${extension}`
}
