import { ConcludeSurgicalOrder } from 'domain/usecases/surgical-order/conclude-surgical-order'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export class RemoteConcludeSurgicalOrder implements ConcludeSurgicalOrder {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  conclude(
    params: ConcludeSurgicalOrder.Params
  ): Promise<ConcludeSurgicalOrder.Model> {
    return this.surgicalOrderRepository.concludeSurgicalOrder(params)
  }
}
