import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  ${({ theme }) => css`
    select {
      display: block;
      width: 100%;
      margin-right: 23px;
    }

    textarea {
      width: 100%;
      margin-bottom: 20px;
    }

    p {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.gray};
      text-align: center;
      line-height: 22px;
    }
  `}
`

export const Diseases = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  div {
    margin-right: 10px;
  }
`

export const Activities = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    margin-right: 12px;
    margin-bottom: 12px;
  }
`

export const DropdownContainer = styled.div<{ fullWidth?: boolean }>`
  display: grid;
  grid-template-columns: ${({ fullWidth }) =>
    fullWidth ? 'auto' : '80px auto'};
  gap: 24px;
  width: 100%;
  margin-top: 12px;
  align-items: flex-end;

  select {
    padding: 0 8px;
  }
`

type LabelProps = {
  disabled?: boolean
}

export const Label = styled.label<LabelProps>`
  ${({ theme, disabled }) => css`
    font-size: ${theme.font.sizes.small};
    font-weight: 400;
    color: ${!disabled ? theme.colors.primary : theme.colors.gray};
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 12px;
    display: block;
  `}
`
