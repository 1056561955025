import { UploadDoctorDocument } from 'domain/usecases/doctor/upload-doctor-document'
import { DoctorRepository } from 'repository/repositories/doctor/doctor-repository'

export class RemoteUploadDoctorDocument implements UploadDoctorDocument {
  constructor(private readonly doctorRepository: DoctorRepository) {}
  upload(
    params: UploadDoctorDocument.Params
  ): Promise<UploadDoctorDocument.Model> {
    return this.doctorRepository.uploadDoctorDocument(params)
  }
}
