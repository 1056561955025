import styled, { css } from 'styled-components'

export const Wrapper = styled.main`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    p {
      margin: 24px 0px;
      color: ${theme.colors.lightGray};
    }
    h2 {
      margin-bottom: 20px;
      line-height: 25.2px;
      font-weight: ${theme.font.black};
    }
  `}
`

export const List = styled.div`
  ${({ theme }) => css`
    background-color: white;
    margin-top: 12px;
    margin-bottom: 40px;
    border-radius: ${theme.border.radius.small};
    li {
      flex-direction: row-reverse;
    }

    img.icon {
      width: 28px;
    }
  `}
`
