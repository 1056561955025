import { Logout } from 'domain/usecases/authentication/logout'
import { AuthenticationRepository } from 'repository/interfaces/authentication-repository'

export class RemoteLogout implements Logout {
  constructor(
    private readonly authenticationRepository: AuthenticationRepository
  ) {}
  logout(params: Logout.Params): Promise<Logout.Model> {
    return this.authenticationRepository.logout(params)
  }
}

export type LogoutModel = Logout.Model
