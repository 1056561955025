import { SearchCollaborators } from 'domain/usecases/collaborator/search-collaborators'
import { CollaboratorRepository } from 'repository/repositories/collaborator/collaborator-repository'

export class RemoteSearchColaborators implements SearchCollaborators {
  constructor(
    private readonly collaboratorRepository: CollaboratorRepository
  ) {}

  search(
    params: SearchCollaborators.Params
  ): Promise<SearchCollaborators.Model> {
    return this.collaboratorRepository.searchCollaborators(params)
  }
}
