import { ILoadUserContacts } from 'domain/usecases/user/load-contacts'
import { UserRepository } from 'repository/repositories/user/user-repository'

export class RemoteLoadUserContacts implements ILoadUserContacts {
  constructor(private readonly userRepository: UserRepository) {}

  async load(
    params: ILoadUserContacts.Params
  ): Promise<ILoadUserContacts.Model> {
    return this.userRepository.loadContacts(params)
  }
}
