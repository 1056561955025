import type { DoctorDocumentValidationRepository as IDoctorDocumentValidationRepository } from 'repository/interfaces/doctor-document-validation-repository'
import type { CreateDoctorDocumentValidation } from 'domain/usecases/doctor-document-validation/create-doctor-document-validation'
import type { IApiRepository } from 'service/protocols/api/api-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import type { DeleteDoctorDocumentValidation } from 'domain/usecases/doctor-document-validation/delete-doctor-document-validation'
import { DeleteDoctorDocumentValidationByIdMutation } from 'repository/graphql/mutations'
import { DoctorDocumentValidation } from 'domain/entities/doctor-document-validation-model'
import { UpdateDoctorDocumentValidation } from 'domain/usecases/doctor-document-validation/update-doctor-document-validation'

export class DoctorDocumentValidationRepository
  implements IDoctorDocumentValidationRepository
{
  constructor(private readonly apiRepository: IApiRepository) {}

  async createDocumentValidation(
    params: CreateDoctorDocumentValidation.Params
  ): Promise<CreateDoctorDocumentValidation.Model> {
    const formData = new FormData()

    const apiRepository = this
      .apiRepository as IApiRepository<CreateDoctorDocumentValidation.Model>

    formData.append('file', params.file)
    formData.append('title', params.title)
    formData.append('description', params.description)
    formData.append('doctor_register_id', String(params.doctor_register_id))

    const httpResponse = await apiRepository.post({
      url: '/doctor-document-validation/upload-doctor-document-validation',
      body: formData
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CreateDoctorDocumentValidation.Model
    }
  }

  async deleteDocumentValidation(
    params: DeleteDoctorDocumentValidation.Params
  ): Promise<DeleteDoctorDocumentValidation.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<DeleteDoctorDocumentValidation.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: DeleteDoctorDocumentValidationByIdMutation.query,
        variables: {
          doctor_document_validation_id: params.doctor_document_validation_id
        }
      },
      query: DeleteDoctorDocumentValidationByIdMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DeleteDoctorDocumentValidation.Model
    }
  }

  async updateDocumentValidation(
    params: UpdateDoctorDocumentValidation.Params
  ): Promise<DoctorDocumentValidation> {
    const {
      doctor_document_validation_id,
      doctor_register_id,
      file,
      title,
      description
    } = params

    const apiRepository = this
      .apiRepository as IApiRepository<UpdateDoctorDocumentValidation.Model>
    const formData = new FormData()

    if (file) {
      formData.append('file', file)
    }

    formData.append('title', title)
    formData.append('description', description)
    formData.append('doctor_register_id', String(doctor_register_id))
    formData.append(
      'doctor_document_validation_id',
      String(doctor_document_validation_id)
    )

    const httpResponse = await apiRepository.post({
      url: '/doctor-document-validation/update-doctor-document-validation',
      body: formData
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateDoctorDocumentValidation.Model
    }
  }
}
