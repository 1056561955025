import React, { ReactElement } from 'react'
import ListArrow from 'presentation/assets/icons/listArrow.png'

import * as S from './styles'

export type ListItem = {
  icon?: string | ReactElement
  title: ReactElement | string
  description?: ReactElement | string
  click?: () => void
  download?: React.ReactNode
  upload?: React.ReactNode
  disabled?: boolean
}

export type ListProps = {
  items?: ListItem[]
  size: 'small' | 'medium'
  arrow?: boolean
  padding?: boolean
  preventIconStyle?: boolean
  spacingIcon?: boolean
}

export default function List({
  items = [],
  size,
  preventIconStyle,
  arrow = false,
  padding = true,
  spacingIcon
}: ListProps) {
  return (
    <S.Wrapper>
      {items.map((item, idx) => (
        <React.Fragment key={idx}>
          <S.Item
            spacingIcon={spacingIcon}
            padding={padding}
            onClick={item.click}
            size={size}
            preventIconStyle={preventIconStyle}
            disabled={item.disabled}
          >
            {item.icon &&
              (typeof item.icon === 'string' ? (
                <img
                  src={item.icon}
                  className="icon"
                  alt="list icon"
                  style={{ width: '31px', height: '31px' }}
                  data-testid={`list-icon-${idx}`}
                />
              ) : (
                item.icon
              ))}
            {item.download}
            {item.upload}
            <S.TextWrapper>
              {typeof item.title === 'string' ? (
                <p>{item.title}</p>
              ) : (
                item.title
              )}{' '}
              {item.description}
            </S.TextWrapper>
            {arrow && (
              <img src={ListArrow} className="arrow" alt="go to icon" />
            )}
          </S.Item>
          {idx !== items.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </S.Wrapper>
  )
}
