import styled, { css } from 'styled-components'

export const ListFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
`

type ListFileThumbnailProps = {
  thumbnailImage?: string
}

export const ListFileThumbnail = styled.div<ListFileThumbnailProps>`
  height: 140px;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 4px solid ${({ theme }) => theme.colors.gray};

  ${(props) =>
    props.thumbnailImage &&
    css`
      background-image: ${`url(${props.thumbnailImage})`};
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: inset 0 0 0 2000px rgba(128, 128, 128, 0.6);
    `}

  border-radius: 10px;
  position: relative;

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  label {
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    text-align: center;
    max-width: 70%;
    margin-left: 4px;
  }

  p {
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    text-align: center;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 138px;
    padding: 4px 2px;
    background-color: ${({ theme }) => theme.colors.gray};
  }

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

export const ListFileActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 6px;
  margin-top: 20px;
`

export const ListFileActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  cursor: pointer;

  img {
    height: 22px;
    margin-right: 4px;
  }

  label {
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    width: 65px;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`
