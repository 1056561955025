import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 40px 0;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 2px 8px -4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
`

export const PreSurgery = styled.section`
  margin: 20px 0;
`

export const MedicObservations = styled.section`
  margin: 20px 0;
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
`

export const Description = styled.p`
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
`
