import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import { useState } from 'react'

type UseUploadSectionProps<ListFileType = unknown> = {
  initialFiles?: ListFile<ListFileType>[]
  maxFiles?: number
  maxFileSizeInMB?: number
  setFilesFunction?: (files: ListFile<ListFileType>[]) => void
  stateFiles?: ListFile<ListFileType>[]
  verifyUniqueFile?: boolean
  extensionsAllowed?: string[]
}

type FilesInfoType = {
  errorMessage?: string[]
  canAdd: boolean
  quantityLeft?: number
}
/**
 * This hook is used to handle the files uploads.
 * It will return the files info and the functions to add, update and delete files.
 * If you are using the hook in a stateless mode, you can pass the files as a prop.
 *
 * [PT-BR]: Este hook é usado para manipular os arquivos de uploads.
 * Ele irá retornar as informações dos arquivos e as funções para adicionar, atualizar e deletar arquivos.
 * Se você está usando o hook em stateless mode, você pode passar os arquivos como uma prop.
 */
export function useUploadSection<ListFileType = unknown>({
  initialFiles,
  maxFiles,
  setFilesFunction,
  stateFiles,
  maxFileSizeInMB,
  verifyUniqueFile,
  extensionsAllowed
}: UseUploadSectionProps<ListFileType>) {
  const mapInitialFiles: ListFile<ListFileType>[] = (initialFiles || []).map(
    (initialFile, index) => ({ ...initialFile, page: index + 1 })
  )
  const [files, setFiles] = useState<ListFile<ListFileType>[]>(
    mapInitialFiles || []
  )
  const mergedFiles = [...(stateFiles || []), ...files]
  const [filesInfo, setFilesInfo] = useState<FilesInfoType>({
    canAdd: maxFiles ? mergedFiles.length < maxFiles : true,
    quantityLeft: maxFiles ? maxFiles - mergedFiles.length : undefined
  })
  const hasMoreSizeText = `O tamanho máximo total dos arquivos é de ${maxFileSizeInMB} MB`
  const hasEqualText = `Não é permitido arquivos duplicado`
  const hasMoreQuantityText = `Você pode fazer upload de apenas ${maxFiles} arquivo${
    (maxFiles || 0) > 1 ? 's' : ''
  }`
  const hasExtensionsNotAllowedText = `Apenas arquivos com as seguintes extensões são permitidos: ${extensionsAllowed?.join(
    ', '
  )}`

  const verifyFilesSizeInMB = (listFiles: ListFile<ListFileType>[]) => {
    if (!maxFileSizeInMB) return true
    const filesSizeInMB = listFiles.reduce(
      (acc, file) => acc + file.file.size / 1024 / 1024,
      0
    )

    return filesSizeInMB <= maxFileSizeInMB
  }

  const verifyFilesQuantity = (listFiles: ListFile<ListFileType>[]) => {
    if (!maxFiles) return true
    return listFiles.length <= maxFiles
  }

  const verifyUniqueFiles = (listFiles: ListFile<ListFileType>[]) => {
    if (!verifyUniqueFile) return false
    const uniqueFiles: Record<string, number> = {}
    return listFiles.some((listFile) => {
      const nameSize = `${listFile.file.name}-${listFile.file.size}`
      if (uniqueFiles[nameSize]) {
        return true
      }
      uniqueFiles[nameSize] = 1
      return false
    })
  }

  const verifyExtensions = (listFiles: ListFile<ListFileType>[]) => {
    if (!extensionsAllowed) return false
    return listFiles.some((listFile) => {
      const extension = String(
        listFile.file.name.split('.').pop()
      ).toLocaleLowerCase()
      return !extensionsAllowed.includes(extension)
    })
  }

  const getFilesInfo = (
    newFiles: ListFile<ListFileType>[]
  ): Pick<FilesInfoType, 'canAdd' | 'quantityLeft'> => {
    return {
      canAdd: maxFiles ? newFiles.length < maxFiles : true,
      quantityLeft: maxFiles ? maxFiles - newFiles.length : undefined
    }
  }

  const allVerifications = (newFiles: ListFile<ListFileType>[]) => {
    const errors: string[] = []
    const hasMoreQuantity = !verifyFilesQuantity(newFiles)
    if (hasMoreQuantity && maxFiles) {
      errors.push(hasMoreQuantityText)
    }
    const hasMoreSize = !verifyFilesSizeInMB(newFiles)
    if (hasMoreSize && maxFileSizeInMB) {
      errors.push(hasMoreSizeText)
    }
    const hasUniqueFiles = verifyUniqueFiles(newFiles)
    if (hasUniqueFiles) {
      errors.push(hasEqualText)
    }
    const hasExtensionsNotAllowed = verifyExtensions(newFiles)
    if (hasExtensionsNotAllowed) {
      errors.push(hasExtensionsNotAllowedText)
    }

    return {
      ...getFilesInfo(newFiles),
      errorMessage: errors
    }
  }

  const updateState = (newFiles: ListFile<ListFileType>[]) => {
    if (setFilesFunction) {
      setFilesFunction(newFiles)
    } else {
      setFiles(newFiles)
    }
  }

  const getFiles = () => {
    if (setFilesFunction) {
      return stateFiles || []
    } else {
      return files
    }
  }

  const onAdd = (newFiles: ListFile<ListFileType>[]) => {
    if (!filesInfo.canAdd) return
    const lastPage = getFiles().slice(-1)[0]?.page || 0
    const newFilesAdded: ListFile<ListFileType>[] = [
      ...mergedFiles,
      ...newFiles.map((file, index) => ({
        ...file,
        page: lastPage + 1 + index
      }))
    ]
    const fileInfo = allVerifications(newFilesAdded)
    if (fileInfo.errorMessage.length === 0) {
      updateState(newFilesAdded)
    } else {
      const { canAdd, quantityLeft } = getFilesInfo(mergedFiles)
      fileInfo.canAdd = canAdd
      fileInfo.quantityLeft = quantityLeft
    }
    setFilesInfo(fileInfo)
  }

  const onUpdate = (listFile: ListFile<ListFileType>) => {
    const newFiles = mergedFiles.map((file) =>
      file.page === listFile.page ? listFile : file
    )
    const fileInfo = allVerifications(newFiles)
    if (fileInfo.errorMessage.length === 0) {
      updateState(newFiles)
    }
    setFilesInfo(fileInfo)
  }

  const onDelete = (listFile: ListFile<ListFileType>) => {
    const newFiles = mergedFiles
      .filter((file) => file.page !== listFile.page)
      .map((file, index) => ({
        ...file,
        page: 1 + index
      }))
    const fileInfo = allVerifications(newFiles)
    if (fileInfo.errorMessage.length === 0) {
      updateState(newFiles)
    }
    setFilesInfo(fileInfo)
  }

  const onDownload = ({ file }: ListFile<ListFileType>) =>
    downloadFileFromBlob(file, file.type, file.name)

  const resetInfo = (newListFile?: ListFile<ListFileType>[]) => {
    if (newListFile) updateState(newListFile)
    const fil = getFilesInfo(newListFile || [])

    setFilesInfo(fil)
  }

  return {
    onUpdate,
    files,
    onAdd,
    onDelete,
    filesInfo,
    onDownload,
    resetInfo,
    allVerifications
  }
}
