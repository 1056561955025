import { UploadToMergeDocument } from 'domain/usecases/surgical-order/upload-to-merge-document'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteUploadToMergeDocument implements UploadToMergeDocument {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  upload(
    params: UploadToMergeDocument.Params
  ): Promise<UploadToMergeDocument.Model> {
    return this.surgicalOrderRepository.uploadToMergeDocument(params)
  }
}
