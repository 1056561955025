import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`

export const TextContainer = styled.div`
  p {
    font-size: ${({ theme }) => theme.font.sizes.medium};

    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`
