import { AddCollaborator } from 'domain/usecases/collaborator/add-collaborator'
import { FinishCollaboratorSignup } from 'domain/usecases/collaborator/finish-collaborator-signup'
import { LoadCollaborator } from 'domain/usecases/collaborator/load-collaborator'
import { SearchCollaborators } from 'domain/usecases/collaborator/search-collaborators'
import { UpdateCollaborator } from 'domain/usecases/collaborator/update-collaborator'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import {
  addCollaboratorMutation,
  finishCollaboratorSignupMutation,
  updateCollaboratorMutation
} from 'repository/graphql/mutations'
import {
  loadCollaboratorQuery,
  searchCollaboratorsQuery
} from 'repository/graphql/queries'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { CollaboratorRepository as ICollaboratorRepository } from 'repository/interfaces/collaborator-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'

export class CollaboratorRepository implements ICollaboratorRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async addCollaborator(
    params: AddCollaborator.Params
  ): Promise<AddCollaborator.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddCollaborator.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: addCollaboratorMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: addCollaboratorMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddCollaborator.Model
    }
  }

  async finishCollaboratorSignup(
    params: FinishCollaboratorSignup.Params
  ): Promise<FinishCollaboratorSignup.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<FinishCollaboratorSignup.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: finishCollaboratorSignupMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: finishCollaboratorSignupMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as FinishCollaboratorSignup.Model
    }
  }

  async load(params: LoadCollaborator.Params): Promise<LoadCollaborator.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadCollaborator.Model>

    const query = loadCollaboratorQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadCollaborator.Model
    }
  }

  async updateCollaborator(
    params: UpdateCollaborator.Params
  ): Promise<UpdateCollaborator.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateCollaborator.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updateCollaboratorMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: updateCollaboratorMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateCollaborator.Model
    }
  }

  async searchCollaborators(
    params: SearchCollaborators.Params
  ): Promise<SearchCollaborators.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SearchCollaborators.Model>

    const query = searchCollaboratorsQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.name, 'name'),
          ...makeGraphQLVariable(params.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SearchCollaborators.Model
    }
  }
}
