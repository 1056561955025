import {
  getCurrentCrmoFilterAdapter,
  setCurrentCrmoFilterAdapter
} from 'main/adapters/current-filters'
import CurrentFilters from 'presentation/contexts/current-filters/current-filters-context'

export default function makeCurrentFilters() {
  return new CurrentFilters(
    setCurrentCrmoFilterAdapter,
    getCurrentCrmoFilterAdapter
  )
}
