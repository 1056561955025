import type { DoctorDocumentValidationRepository } from 'repository/repositories/doctor-document-validation/doctor-document-validation-repository'
import type { DeleteDoctorDocumentValidation } from 'domain/usecases/doctor-document-validation/delete-doctor-document-validation'

export class RemoteDeleteDoctorDocumentValidation
  implements DeleteDoctorDocumentValidation
{
  constructor(
    private readonly doctorDocumentValidationRepository: DoctorDocumentValidationRepository
  ) {}

  delete(
    params: DeleteDoctorDocumentValidation.Params
  ): Promise<DeleteDoctorDocumentValidation.Model> {
    return this.doctorDocumentValidationRepository.deleteDocumentValidation(
      params
    )
  }
}
