import { ResendDoctorInvite } from 'domain/usecases/doctor/resendDoctorInvite'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteResendDoctorInvite implements ResendDoctorInvite {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  resend(params: ResendDoctorInvite.Params): Promise<ResendDoctorInvite.Model> {
    return this.doctorRepository.resendDoctorInvite(params)
  }
}
