import { SearchPatient } from 'domain/usecases/patient/search-patient'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteSearchPatient implements SearchPatient {
  constructor(private readonly patientRepository: PatientRepository) {}

  load(params: SearchPatient.Params): Promise<SearchPatient.Model> {
    return this.patientRepository.searchPatients(params)
  }
}
