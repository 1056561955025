import { LoadSpecialties } from 'domain/usecases/specialties/load-specialties'
import { SpecialtyRepository } from 'repository/interfaces/specialty-repository'

export class RemoteLoadSpecialties implements LoadSpecialties {
  constructor(private readonly specialtyRepository: SpecialtyRepository) {}

  load(params: LoadSpecialties.Params): Promise<LoadSpecialties.Model> {
    return this.specialtyRepository.loadSpecialties(params)
  }
}
