import React from 'react'
import * as S from './styles'
import { ReactComponent as AlertIcon } from 'presentation/assets/icons/alert.svg'

type UploadListErrorsProps = {
  errors: string[]
}

export function UploadListErrors({ errors }: UploadListErrorsProps) {
  return (
    <S.UploadListErrorsContainer>
      {errors.map((error, index) => (
        <S.ListError key={`${error} - ${index}`}>
          <AlertIcon />
          <label>{error}</label>
        </S.ListError>
      ))}
    </S.UploadListErrorsContainer>
  )
}
