import { LoadPatientSchedules } from 'domain/usecases/patient/load-patient-schedules'
import { PatientRepository } from 'repository/interfaces/patient-repository'
import { Interval, Pagination } from 'service/protocols/api/api-client'

export class RemoteLoadPatientSchedules implements LoadPatientSchedules {
  constructor(private readonly patientRepository: PatientRepository) {}

  load(
    params?: LoadPatientSchedules.Params,
    pagination?: Pagination,
    interval?: Interval
  ): Promise<LoadPatientSchedules.Model[]> {
    return this.patientRepository.loadPatientSchedules(
      params,
      pagination,
      interval
    )
  }
}

export type LoadPatientSchedulesModel = LoadPatientSchedules.Model
