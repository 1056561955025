import { CreateDoctorDocumentValidation } from 'domain/usecases/doctor-document-validation/create-doctor-document-validation'
import { DoctorDocumentValidationRepository } from 'repository/repositories/doctor-document-validation/doctor-document-validation-repository'

export class RemoteCreateDoctorDocumentValidation
  implements CreateDoctorDocumentValidation
{
  constructor(
    private readonly doctorDocumentValidationRepository: DoctorDocumentValidationRepository
  ) {}

  create(
    params: CreateDoctorDocumentValidation.Params
  ): Promise<CreateDoctorDocumentValidation.Model> {
    return this.doctorDocumentValidationRepository.createDocumentValidation(
      params
    )
  }
}
