import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import SecretaryService from 'presentation/contexts/services/secretary-service'
import { ApiRepository } from 'repository/api-repository'
import { SecretaryRepository } from 'repository/repositories/secretary/secretary-repository'
import { RemoteGetSecretaryByCpf } from 'service/usecases/get-secretary-by-cpf/remote-get-secretary-by-cpf'
import { RemoteLoadSecretaryPendencies } from 'service/usecases/load-secretary-pendencies'
import { RemoteLoadSecretarySurgicalPendenciesCount } from 'service/usecases/load-secretary-surgical-pendencies-count'

export default function makeSecretaryService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const secretaryRepository = new SecretaryRepository(apiRepository)

  const loadSecretaryPendencies = new RemoteLoadSecretaryPendencies(
    secretaryRepository
  )
  const countSurgicalPendencies =
    new RemoteLoadSecretarySurgicalPendenciesCount(secretaryRepository)

  const getSecretaryByCpf = new RemoteGetSecretaryByCpf(secretaryRepository)

  return new SecretaryService({
    loadSecretaryPendencies,
    countSurgicalPendencies,
    getSecretaryByCpf
  })
}
