import theme from 'presentation/styles/theme'
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction
} from 'react'
import * as S from './styles'

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>

export type ButtonProps = {
  outlined?: boolean
  fullWidth?: boolean
  as?: React.ElementType
  variant?: 'outlined' | 'white' | 'text'
  backgroundColor?: keyof typeof theme.colors
} & ButtonTypes

const Button: ForwardRefRenderFunction<S.WrapperProps, ButtonProps> = (
  { fullWidth = false, ...props }: ButtonProps,
  ref
) => (
  <S.Wrapper fullWidth={fullWidth} ref={ref} {...props}>
    {!!props.children && <span>{props.children}</span>}
  </S.Wrapper>
)

export default forwardRef(Button)
