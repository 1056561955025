import { CustomError } from 'common/errors'

export default function handleCustomError(error: any) {
  if (Array.isArray(error?.errors)) {
    const errors = error?.errors as IError[]
    const messages: ErrorMessage[] = []
    errors?.forEach((err) => {
      if (Array.isArray(err.message)) {
        messages.push(...err.message)
      }
    })
    let message: string | undefined
    messages.forEach((messageFor) => {
      message =
        message && messageFor.type === 'Repository'
          ? message
          : messageFor.friendlyMessage
    })
    if (message) return new CustomError(message)
  }
}

interface IError {
  code: string
  statusCode: number
  message?: ErrorMessage[]
}

interface ErrorMessage {
  type?: string
  message?: string
  friendlyMessage?: string
}
