export const codeFields: {
  name: 'codeChar1' | 'codeChar2' | 'codeChar3' | 'codeChar4'
}[] = [
  {
    name: 'codeChar1'
  },
  {
    name: 'codeChar2'
  },
  {
    name: 'codeChar3'
  },
  {
    name: 'codeChar4'
  }
]
