import { LoadHealthInsurance } from 'domain/usecases/health-insurance/load-health-insurance'
import { HealthInsuranceRepository } from 'repository/interfaces/health-insurance-repository'

export class RemoteLoadHealthInsurance implements LoadHealthInsurance {
  constructor(
    private readonly healthInsuranceRepository: HealthInsuranceRepository
  ) {}

  load(params: LoadHealthInsurance.Params): Promise<LoadHealthInsurance.Model> {
    return this.healthInsuranceRepository.load(params)
  }
}

export type LoadHealthInsuranceModel = LoadHealthInsurance.Model
