import { DeleteUploadedDocuments } from 'domain/usecases/surgical-order/delete-uploaded-documents'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteDeleteUploadedDocuments implements DeleteUploadedDocuments {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  delete(
    params: DeleteUploadedDocuments.Params
  ): Promise<DeleteUploadedDocuments.Model> {
    return this.surgicalOrderRepository.deleteUploadedDocuments(params)
  }
}
