import { AxiosResponse } from 'axios'
import {
  ApiClient,
  ApiRequest,
  ApiResponse,
  ApiMethod
} from 'service/protocols/api/api-client'
import {
  IApiRepository,
  RepositoryRequest
} from 'service/protocols/api/api-repository'

export class ApiRepository<Model = any> implements IApiRepository<Model> {
  constructor(private readonly apiClient: ApiClient<Model>) {}

  async get(
    data: RepositoryRequest,
    is_file?: boolean
  ): Promise<ApiResponse<Model> | AxiosResponse> {
    if (is_file) {
      return await this.apiClient.requestFile({
        ...data,
        method: ApiMethod.GET
      } as ApiRequest)
    }

    return await this.apiClient.request({
      ...data,
      method: ApiMethod.GET
    } as ApiRequest)
  }

  async post(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    const request = await this.apiClient.request({
      ...data,
      method: ApiMethod.POST
    } as ApiRequest)

    if (request.body && data.query) {
      request.body = (request.body as any).data
        ? (request.body as any).data[data.query]
        : request.body
    }

    return request
  }

  async put(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    return await this.apiClient.request({
      ...data,
      method: ApiMethod.PUT
    } as ApiRequest)
  }

  delete(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    return this.apiClient.request({
      ...data,
      method: ApiMethod.DELETE
    } as ApiRequest)
  }
}
