import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'

import { SpecialtyRepository } from 'repository/repositories/specialty/specialty-repository'
import { RemoteLoadSpecialties } from 'service/usecases/load-specialties/remote-load-specialties'
import SpecialtyService from 'presentation/contexts/services/specialty-service'

export default function makeSpecialtyService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const specialtyRepository = new SpecialtyRepository(apiRepository)

  const loadSpecialties = new RemoteLoadSpecialties(specialtyRepository)

  return new SpecialtyService({
    loadSpecialties
  })
}
