import styled from 'styled-components'
import { Slider, withStyles } from '@material-ui/core'
import theme from 'presentation/styles/theme'

const MuiSlider = withStyles({
  root: {
    height: 8
  },
  thumb: {
    height: 20,
    width: 20,
    background: '#EA744C',
    marginTop: -5,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {},
  track: {
    height: 8,
    borderRadius: 4,
    background: 'linear-gradient(90deg, #AFF05C 0%, #EA744C 118.08%)'
  },
  rail: {
    height: 8,
    borderRadius: 4
  },
  mark: {
    background: 'transparent',
    color: 'red',
    marginBottom: 2
  },
  markLabel: {
    marginTop: -55,
    marginLeft: -3,
    fontSize: theme.font.sizes.small,
    color: theme.colors.lightGray
  }
})(Slider)

export const StyledMuiSlider = styled(MuiSlider)`
  [data-index='${({ value }) => (value as number) - 1}']:not([role='slider']) {
    color: ${({ value }) => colors[(value as number) - 1]};
    font-weight: bold;
    font-size: 18px;
    margin-top: -59px;
  }

  [role='slider'] {
    background: ${({ value }) => colors[(value as number) - 1]};
  }

  .MuiSlider-track {
    background: linear-gradient(
      90deg,
      #aff05c 0%,
      #ea744c ${({ value }) => 100 + 18 * (10 - (value as number))}%
    );
  }
`
const colors = [
  '#aff05c', //1
  '#bed158', //2
  '#c6c155', //3
  '#c8bb55', //4
  '#cbb554', //5
  '#ccb354', //6
  '#d2a552', //7
  '#d99851', //8
  '#de8c4f', //9
  '#e8794d' //10
]

export const SupportTexts = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
