import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import AccompanyingService from 'presentation/contexts/services/accompanying-service'
import { ApiRepository } from 'repository/api-repository'
import { AccompanyingRepository } from 'repository/repositories/accompanying/accompanying-repository'
import { RemoteUpdateAccompanying } from 'service/usecases/update-accompanying/remote-update-accompanying'

export default function makeAccompanyingService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const accompanyingRepository = new AccompanyingRepository(apiRepository)

  const updateAccompanying = new RemoteUpdateAccompanying(
    accompanyingRepository
  )

  return new AccompanyingService({
    updateAccompanying
  })
}
