import { LoadCollaborator } from 'domain/usecases/collaborator/load-collaborator'
import { SearchCollaborators } from 'domain/usecases/collaborator/search-collaborators'
import { UpdateCollaborator } from 'domain/usecases/collaborator/update-collaborator'
import { makeAutoObservable } from 'mobx'

type Services = {
  updateCollaborator: UpdateCollaborator
  loadCollaborator: LoadCollaborator
  searchCollaborators: SearchCollaborators
}

export class CollaboratorService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async updateCollaborator(
    params: UpdateCollaborator.Params
  ): Promise<UpdateCollaborator.Model> {
    return this.services.updateCollaborator.update(params)
  }

  async loadCollaborator(
    params: LoadCollaborator.Params
  ): Promise<LoadCollaborator.Model> {
    return this.services.loadCollaborator.load(params)
  }

  async searchCollaborators(
    params: SearchCollaborators.Params
  ): Promise<SearchCollaborators.Model> {
    return this.services.searchCollaborators.search(params)
  }
}

export default CollaboratorService
