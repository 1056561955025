import theme from 'presentation/styles/theme'
import React, { AllHTMLAttributes } from 'react'
import * as S from './styles'

export type Props = {
  label?: string
  color?: keyof typeof theme.colors
  fontSize?: keyof typeof theme.font.sizes
} & Omit<AllHTMLAttributes<HTMLDivElement>, 'children' | 'as'>

const StatusChip = ({ label, color, fontSize, ...props }: Props) => {
  return (
    <S.ChipContainer {...props}>
      <S.ChipLabel color={color} fontSize={fontSize}>
        {label}
      </S.ChipLabel>
      <></>
    </S.ChipContainer>
  )
}

export default StatusChip
