import { LoadDoctorPendencies } from 'domain/usecases/doctor/load-doctor-pendencies'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadDoctorPendencies implements LoadDoctorPendencies {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(
    params: LoadDoctorPendencies.Params
  ): Promise<LoadDoctorPendencies.Model> {
    return this.doctorRepository.loadDoctorPendencies(params)
  }
}

export type LoadDoctorPendenciesModel = LoadDoctorPendencies.Model
