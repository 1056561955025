import { CrmoFilter } from 'main/adapters/current-filters'
import { makeAutoObservable } from 'mobx'

export class CurrentFilters {
  constructor(
    private setCurrentCrmoFilter: (crmoFilter: CrmoFilter) => void,
    private getCurrentCrmoFilter: () => CrmoFilter
  ) {
    makeAutoObservable(this)
  }
  crmoFilter?: CrmoFilter = undefined
  getCrmoFilter() {
    return this.getCurrentCrmoFilter()
  }
  setCrmoFilter(newCrmoFilter: CrmoFilter) {
    this.crmoFilter = newCrmoFilter
    this.setCurrentCrmoFilter(newCrmoFilter)
  }
}

export default CurrentFilters
