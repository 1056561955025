import type { DoctorDocumentValidationRepository } from 'repository/repositories/doctor-document-validation/doctor-document-validation-repository'
import type { UpdateDoctorDocumentValidation } from 'domain/usecases/doctor-document-validation/update-doctor-document-validation'

export class RemoteUpdateDoctorDocumentValidation
  implements UpdateDoctorDocumentValidation
{
  constructor(
    private readonly doctorDocumentValidationRepository: DoctorDocumentValidationRepository
  ) {}

  update(
    params: UpdateDoctorDocumentValidation.Params
  ): Promise<UpdateDoctorDocumentValidation.Model> {
    return this.doctorDocumentValidationRepository.updateDocumentValidation(
      params
    )
  }
}
