import { AuthorizationCheck } from 'domain/usecases/authorization/authorization'
import { makeAutoObservable } from 'mobx'

type Services = {
  authorizationCheck: AuthorizationCheck
}

export class AuthorizationService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async authorizationCheck(
    data: AuthorizationCheck.Data,
    params: AuthorizationCheck.Params
  ): Promise<AuthorizationCheck.Model> {
    return this.services.authorizationCheck.check(data, params)
  }
}

export default AuthorizationService
