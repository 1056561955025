import { LoadDoctorByInviteToken } from 'domain/usecases/doctor/load-doctor-by-invite-token'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadDoctorByInviteToken implements LoadDoctorByInviteToken {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(
    token: string,
    params?: LoadDoctorByInviteToken.Params
  ): Promise<LoadDoctorByInviteToken.Model> {
    return this.doctorRepository.loadDoctorByInviteToken(token, params)
  }
}

export type LoadDoctorByInviteTokenModel = LoadDoctorByInviteToken.Model
