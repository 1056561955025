import { LoadSpecialties } from 'domain/usecases/specialties/load-specialties'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { loadSpecialties } from 'repository/graphql/queries'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { SpecialtyRepository as ISpecialtyRepository } from 'repository/interfaces/specialty-repository'

import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'

export class SpecialtyRepository implements ISpecialtyRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async loadSpecialties(
    params: LoadSpecialties.Params
  ): Promise<LoadSpecialties.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSpecialties.Model>

    const query = loadSpecialties(params)
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.name, 'query'),
          ...makeGraphQLVariable(params?.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSpecialties.Model
    }
  }
}
