import { UploadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/upload-surgical-pendency-document'
import { SurgicalPendencyRepository } from 'repository/interfaces/surgical-pendency-repository'

export class RemoteUploadSurgicalPendencyDocument
  implements UploadSurgicalPendencyDocument
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalPendencyRepository
  ) {}
  upload(
    params: UploadSurgicalPendencyDocument.Params
  ): Promise<UploadSurgicalPendencyDocument.Model> {
    return this.surgicalOrderRepository.uploadSurgicalPendencyDocument(params)
  }
}
