import { UpdatePatientById } from 'domain/usecases/patient/update-patient-by-id'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteUpdatePatientById implements UpdatePatientById {
  constructor(private readonly patientRepository: PatientRepository) {}

  update(params: UpdatePatientById.Params): Promise<UpdatePatientById.Model> {
    return this.patientRepository.updatePatientById(params)
  }
}
