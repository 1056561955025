export enum ActingSectors {
  // Pronto Socorro (PSO)
  EMERGENCY_ROOM = 'EMERGENCY_ROOM',

  INPATIENT_UNIT = 'INPATIENT_UNIT',

  // Centro de Tratamento Intensivo (CTI-Geral)
  INTENSIVE_TREATMENT_CENTER = 'INTENSIVE_TREATMENT_CENTER',

  // Centro de Tratamento Intensivo- Unidade Coronariana (CTI-UCO)
  INTENSIVE_TREATMENT_CENTER_CORONARY = 'INTENSIVE_TREATMENT_CENTER_CORONARY',

  // Unidade de Terapia Intensiva Neonatal (UTIN)
  NEONATAL_INTENSIVE_CARE_UNIT = 'NEONATAL_INTENSIVE_CARE_UNIT',

  // Unidade de Terapia Intensiva Pediátrica (UTIP)
  PEDIATRIC_INTENSIVE_CARE_UNIT = 'PEDIATRIC_INTENSIVE_CARE_UNIT',

  AMBULATORY = 'AMBULATORY',
  SURGICAL_CENTER = 'SURGICAL_CENTER',
  WOUND_CENTER = 'WOUND_CENTER',
  ONCOLOGY = 'ONCOLOGY',
  DIAGNOSTIC_MEDICINE = 'DIAGNOSTIC_MEDICINE',
  HEMODYNAMICS = 'HEMODYNAMICS',
  ENDOSCOPY = 'ENDOSCOPY'
}
