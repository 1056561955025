import React from 'react'
import { GetPasswordRecoveryCode } from 'domain/usecases/authentication/get-password-recovery-code'
import { useParams } from 'react-router'
import { ChangePassword } from 'domain/usecases/authentication/change-password'
import PasswordRecoveryLayout from 'presentation/shared/layouts/PasswordRecovery'
import { Profile } from 'common/enum/profile'

type PasswordRecoveryParams = {
  type: 'phone' | 'email'
}

type PasswordRecoveryProps = {
  getCodeUseCase: GetPasswordRecoveryCode
  changePasswordUseCase: ChangePassword
  client?: Profile
}

export default function PasswordRecovery({
  getCodeUseCase,
  changePasswordUseCase,
  client
}: PasswordRecoveryProps) {
  const { type } = useParams<PasswordRecoveryParams>()

  const setInitialValues: GetPasswordCodeFormValues = {
    type: type,
    field: ''
  }

  return (
    <PasswordRecoveryLayout
      getPasswordCodeInitialValues={setInitialValues}
      setPasswordInitialValues={{} as SetPasswordFormValues}
      getCodeUseCase={getCodeUseCase}
      setPasswordUseCase={changePasswordUseCase}
      type={type}
      client={client}
    />
  )
}

export type GetPasswordCodeFormValues = {
  field: string
  type: 'phone' | 'email'
}

export type SetPasswordFormValues = {
  codeChar1: string
  codeChar2: string
  codeChar3: string
  codeChar4: string
  password: string
  passwordConfirmation: string
}
