import React from 'react'
import * as S from './styles'
import { UploadActions, UploadActionsProps } from './UploadActions'
import { UploadListFileProps, UploadListFiles } from './UploadListFiles'
import { UploadTitle, UploadTitleProps } from './UploadTitle'

type UploadSectionProps<ListFileType = unknown> = {
  showTitle?: boolean
  showActions?: boolean
  titleProps?: UploadTitleProps
  actionProps?: UploadActionsProps
} & UploadListFileProps<ListFileType>

export function UploadSection<ListFileType = unknown>({
  showTitle,
  showActions,
  titleProps,
  actionProps,
  ...rest
}: UploadSectionProps<ListFileType>) {
  return (
    <S.UploadSectionContainer>
      {showTitle && <UploadTitle {...titleProps} />}
      <UploadListFiles {...rest} />
      {showActions && <UploadActions {...actionProps} />}
    </S.UploadSectionContainer>
  )
}
