import React, { useEffect, useState } from 'react'

import * as S from './styles'

export type TabItem = {
  label: string
  componentChildren: React.ReactElement
  disabled?: boolean
}

type Props = {
  tabItems: TabItem[]
  defaultTab?: number
  styleOptions?: {
    primary?: boolean
  }
  onChangeTab?(activeIndex: number): void
}

const Tabs = ({ tabItems, styleOptions, onChangeTab, defaultTab }: Props) => {
  const [activeIndex, setActiveIndex] = useState(defaultTab || 0)
  const [activePanel, setActivePanel] = useState((<></>) as React.ReactElement)

  const handleChange = (newIndex: number) => {
    const findedTab = tabItems.find((e, idx) => idx === newIndex)
    if (findedTab && findedTab.disabled) {
      return
    }
    setActiveIndex(newIndex)
    if (onChangeTab) {
      onChangeTab(newIndex)
    }
  }

  useEffect(() => {
    const tabFinded = tabItems.find((tab, idx) => tab && idx === activeIndex)
    if (tabFinded) {
      setActivePanel(tabFinded.componentChildren)
    }
  }, [activeIndex, tabItems])

  return (
    <S.Wrapper>
      <S.StyledTabs
        value={activeIndex}
        onChange={(e, v) => handleChange(v)}
        centered
        variant="fullWidth"
        primary={styleOptions?.primary ? 'true' : undefined}
        data-testid="tabs-container"
      >
        {tabItems.map((tab, idx) => (
          <S.StyledTab
            key={idx}
            label={tab.label}
            style={{
              opacity: tab.disabled ? 0.4 : 1
            }}
            primary={styleOptions?.primary ? 'true' : undefined}
            data-testid="tab-container"
          />
        ))}
      </S.StyledTabs>
      <S.ActiveTabPanel>{activePanel}</S.ActiveTabPanel>
    </S.Wrapper>
  )
}

export default Tabs
