import { FinishDoctorSurgicalOrder } from 'domain/usecases/doctor/finish-doctor-surgical-order'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteFinishDoctorSurgicalOrder
  implements FinishDoctorSurgicalOrder
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  add(
    params: FinishDoctorSurgicalOrder.Params
  ): Promise<FinishDoctorSurgicalOrder.Model> {
    return this.doctorRepository.finishDoctorSurgicalOrder(params)
  }
}
