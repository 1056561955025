import { UploadSurgicalOrderDocumentsSameType } from 'domain/usecases/surgical-order/upload-surgical-order-documents-same-type'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteUploadSurgicalOrderDocumentsSameType
  implements UploadSurgicalOrderDocumentsSameType
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}
  upload(
    params: UploadSurgicalOrderDocumentsSameType.Params
  ): Promise<UploadSurgicalOrderDocumentsSameType.Model> {
    return this.surgicalOrderRepository.uploadSurgicalOrderDocumentsSameType(
      params
    )
  }
}
