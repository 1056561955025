import styled from 'styled-components'
import TextAreaComponent from 'presentation/shared/components/TextArea'

export const TextArea = styled(TextAreaComponent)`
  font-size: 14px;
`

export const DocumentsButton = styled.div`
  display: grid;
  grid-template-columns: auto 11px;
  width: 100%;
  min-height: 60px;
  margin: 20px 0;
  padding: 12px 24px;
  line-height: 21px;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`
