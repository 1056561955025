import { AddSecretary } from 'domain/usecases/secretary/add-secretary'
import { FinishSecretarySignup } from 'domain/usecases/secretary/finish-secretary-signup'
import { GetSecretaryByCpf } from 'domain/usecases/secretary/get-secretary-by-cpf'
import { LoadSecretary } from 'domain/usecases/secretary/load-secretary'
import { LoadSecretaryPendencies } from 'domain/usecases/secretary/load-secretary-pendencies'
import { LoadSecretaryRegisteredOrdersCount } from 'domain/usecases/secretary/load-secretary-registered-orders-count'
import { LoadSecretaryRequestCount } from 'domain/usecases/secretary/load-secretary-request-count'
import { LoadSecretaryScheduleSurgicalOrders } from 'domain/usecases/secretary/load-secretary-schedule-surgical-orders'
import { LoadSecretarySurgicalOrders } from 'domain/usecases/secretary/load-secretary-surgical-order'
import { LoadSecretarySurgicalPendenciesCount } from 'domain/usecases/secretary/load-secretary-surgical-pendencies-count'
import { UnlinkSecretary } from 'domain/usecases/secretary/unlink-secretary'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import {
  addSecretaryMutation,
  finishSecretarySignupMutation,
  unlinkSecretaryMutation
} from 'repository/graphql/mutations'
import {
  getSecretary,
  getSecretaryRegisteredOrdersCountQuery,
  getSecretaryScheduleSurgicalOrders,
  getSecretarySurgeryRequestsCountQuery,
  getSecretarySurgicalOrders,
  getSecretarySurgicalPendenciesCountQuery,
  loadSecretaryPendenciesQuery,
  getSecretaryByCpf
} from 'repository/graphql/queries'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { SecretaryRepository as ISecretaryRepository } from 'repository/interfaces/secretary-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { AddSecretaryModel } from 'service/usecases/add-secretary/remote-add-secretary'

export class SecretaryRepository implements ISecretaryRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async addSecretary(params: AddSecretary.Params): Promise<AddSecretary.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddSecretary.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: addSecretaryMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: addSecretaryMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddSecretaryModel
    }
  }

  async finishSecretarySignup(
    params: FinishSecretarySignup.Params
  ): Promise<FinishSecretarySignup.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddSecretary.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: finishSecretarySignupMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: finishSecretarySignupMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddSecretaryModel
    }
  }

  async countSurgeryRequests(
    params: LoadSecretaryRequestCount.Params
  ): Promise<LoadSecretaryRequestCount.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSecretaryRequestCount.Model>

    const query = getSecretarySurgeryRequestsCountQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSecretaryRequestCount.Model
    }
  }

  async countRegisteredOrders(
    params: LoadSecretaryRegisteredOrdersCount.Params
  ): Promise<LoadSecretaryRegisteredOrdersCount.Model[]> {
    const apiRepository = this.apiRepository as IApiRepository<
      LoadSecretaryRegisteredOrdersCount.Model[]
    >

    const query = getSecretaryRegisteredOrdersCountQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    switch (httpResponse.statusCode) {
      case ApiStatusCode.ok:
        return httpResponse.body as LoadSecretaryRegisteredOrdersCount.Model[]
      default:
        throw new Error()
    }
  }

  async loadSurgicalOrders(
    params: LoadSecretarySurgicalOrders.Params
  ): Promise<LoadSecretarySurgicalOrders.Model> {
    const apiRepository = this.apiRepository as IApiRepository<{
      surgicalOrders: LoadSecretarySurgicalOrders.Model
    }>

    const query = getSecretarySurgicalOrders(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.filters?.search, 'search'),
          ...makeGraphQLVariable(params.filters?.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return (
        (httpResponse.body
          ?.surgicalOrders as LoadSecretarySurgicalOrders.Model) ??
        ({} as LoadSecretarySurgicalOrders.Model)
      )
    }
  }

  async loadSecretary(
    params?: LoadSecretary.Params
  ): Promise<LoadSecretary.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSecretary.Model>

    const query = getSecretary(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSecretary.Model
    }
  }

  async unlinkSecretary(
    params: UnlinkSecretary.Params
  ): Promise<UnlinkSecretary.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UnlinkSecretary.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: unlinkSecretaryMutation.query,
        variables: makeGraphQLVariable(params.secretary_id, 'secretary_id')
      },
      query: unlinkSecretaryMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UnlinkSecretary.Model
    }
  }

  async loadSecretaryPendencies(
    params: LoadSecretaryPendencies.Params
  ): Promise<LoadSecretaryPendencies.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSecretaryPendencies.Model>

    const { query, name } = loadSecretaryPendenciesQuery(params?.fields)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query,
        variables: {
          ...makeGraphQLVariable(params.filters?.doctorName, 'doctorName'),
          ...makeGraphQLVariable(params.filters?.patientName, 'patientName'),
          ...makeGraphQLVariable(
            params.filters?.surgicalOrderId,
            'surgicalOrderId'
          ),
          ...makeGraphQLVariable(params.filters?.type, 'type'),
          ...makeGraphQLVariable(params.pagination, 'pagination')
        }
      },
      query: name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSecretaryPendencies.Model
    }
  }

  async countSurgicalPendencies(
    params: LoadSecretarySurgicalPendenciesCount.Params
  ): Promise<LoadSecretarySurgicalPendenciesCount.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSecretarySurgicalPendenciesCount.Model>

    const query = getSecretarySurgicalPendenciesCountQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSecretarySurgicalPendenciesCount.Model
    }
  }

  async loadScheduleSurgicalOrders(
    params: LoadSecretaryScheduleSurgicalOrders.Params
  ): Promise<LoadSecretaryScheduleSurgicalOrders.Model> {
    const apiRepository = this.apiRepository as IApiRepository<{
      scheduleSurgicalOrders: LoadSecretaryScheduleSurgicalOrders.Model
    }>

    const query = getSecretaryScheduleSurgicalOrders(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.filters?.search, 'search'),
          ...makeGraphQLVariable(params.filters?.orderStatus, 'orderStatus'),
          ...makeGraphQLVariable(params.filters?.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body
        ?.scheduleSurgicalOrders as LoadSecretaryScheduleSurgicalOrders.Model
    }
  }

  async getSecretaryByCpf(
    params: GetSecretaryByCpf.Params
  ): Promise<GetSecretaryByCpf.Model> {
    const apiRepository = this.apiRepository as IApiRepository<{
      scheduleSurgicalOrders: GetSecretaryByCpf.Model
    }>

    const query = getSecretaryByCpf(params.fields)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.cpf, 'cpf')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetSecretaryByCpf.Model
    }
  }
}
