import { LoadUserRole } from 'domain/usecases/authentication/load-user-role'
import { AuthenticationRepository } from 'repository/interfaces/authentication-repository'

export class RemoteLoadUserRole implements LoadUserRole {
  constructor(
    private readonly authenticationRepository: AuthenticationRepository
  ) {}

  load(params?: LoadUserRole.Params): Promise<LoadUserRole.Model> {
    return this.authenticationRepository.loadUserRole(params)
  }
}

export type LoadUserRoleModel = LoadUserRole.Model
