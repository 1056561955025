import NoImage from 'presentation/assets/images/no-image.jpg'

// @ts-ignore
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf'
// @ts-ignore
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry'
pdfjs.GlobalWorkerOptions.workerSrc = PDFJSWorker

export const getPdfThumbnail = async (file: File) => {
  const canvas = document.createElement('canvas')
  const pdf = await pdfjs.getDocument(URL.createObjectURL(file)).promise
  const page = await pdf.getPage(1)
  const viewport = page.getViewport({ scale: 1 })
  const context = canvas.getContext('2d')
  canvas.height = viewport.height
  canvas.width = viewport.width
  if (context) {
    await page.render({ canvasContext: context, viewport: viewport }).promise
    return canvas.toDataURL()
  }

  return NoImage
}
