import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { ApiRepository } from 'repository/api-repository'
import { makeLocalStorageAdapter } from '../cache/local-storage-adapter-factory'

export const makeApiRepository = (): PrepareApiRequestDecorator => {
  const apiClient = new ApiClientImplementation()
  return new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
}
