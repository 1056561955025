import styled from 'styled-components'

export const Wrapper = styled.div``

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  margin: 12px 0;
  right: 12px;

  button {
    height: 45px;
    width: 80px;
  }
`

export const ImageView = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
`

export const PdfView = styled.iframe`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  height: 65vh;
  width: 70vw;
`
