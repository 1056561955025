import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import AuthorizationService from 'presentation/contexts/services/authorization-service'
import { ApiRepository } from 'repository/api-repository'
import { AuthorizationRepository } from 'repository/repositories/authorization/authorization-repository'
import { RemoteAuthorizationCheck } from 'service/usecases/authorization/remote-authorization'

export default function makeAuthorizationService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const authorizationRepository = new AuthorizationRepository(apiRepository)

  const authorizationCheck = new RemoteAuthorizationCheck(
    authorizationRepository
  )

  return new AuthorizationService({
    authorizationCheck
  })
}
