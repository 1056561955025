import styled from 'styled-components'

export const WppButton = styled.button`
  padding: 0.5rem 1rem;
  border: ${({ theme }) => '1px solid' + theme.colors.primary};
  background: none;
  border-radius: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  width: 90%;
  display: flex;
`
export const TitleButton = styled.p`
  margin: 7px 0 0 5px;
  font-size: 15px;
`
