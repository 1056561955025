import { EligibilityCheck } from 'domain/usecases/eligibility/eligibility-check'
import { EligibilityRepository } from 'repository/interfaces/eligibility-repository'

export class RemoteEligibilityCheck implements EligibilityCheck {
  constructor(private readonly eligibilityRepository: EligibilityRepository) {}

  async check(
    data: EligibilityCheck.Data,
    params: EligibilityCheck.Params
  ): Promise<EligibilityCheck.Model> {
    return await this.eligibilityRepository.eligibilityCheck(data, params)
  }
}

export type RemoteEligibilityCheckModel = EligibilityCheck.Model
