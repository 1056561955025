import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const LoadingContainer = styled.div`
  margin: auto;
  width: 100%;
`

export const LoadingText = styled.span`
  display: inline-block;
  color: #4a4a4a;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 32px;
  width: 100% !important;
  text-align: start;
  padding-left: 50%;
  transform: translateX(-30%);
`
