import { LoadSurgicalOrderStatus } from 'domain/usecases/surgical-order/get-surgical-order-status'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadSurgicalOrderStatus implements LoadSurgicalOrderStatus {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  async load(
    params: LoadSurgicalOrderStatus.Params
  ): Promise<LoadSurgicalOrderStatus.Model> {
    return this.surgicalOrderRepository.loadSurgicalOrderStatus(params)
  }
}

export type LoadSurgicalOrderStatusModel = LoadSurgicalOrderStatus.Model
