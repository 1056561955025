import { ChangeHospital } from 'domain/usecases/hospital/change-hospital'
import { HospitalRepository } from 'repository/interfaces/hospital-repository'

export class RemoteChangeHospital implements ChangeHospital {
  constructor(private readonly hospitalRepository: HospitalRepository) {}

  update(params: ChangeHospital.Params): Promise<ChangeHospital.Model> {
    return this.hospitalRepository.changeHospital(params)
  }
}

export type ChangePasswordModel = ChangeHospital.Model
