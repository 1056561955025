import { AcceptUserTerm } from 'domain/usecases/user/acceptUserTerm'
import { UserRepository } from 'repository/repositories/user/user-repository'

export class RemoteUserAcceptTerm implements AcceptUserTerm {
  constructor(private readonly userRepository: UserRepository) {}

  acceptUserTerm(params: AcceptUserTerm.Params): Promise<AcceptUserTerm.Model> {
    return this.userRepository.acceptTerm(params)
  }
}
