import { SearchDoctors } from 'domain/usecases/doctor/search-doctors'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteSearchDoctors implements SearchDoctors {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(params: SearchDoctors.Params): Promise<SearchDoctors.Model> {
    return this.doctorRepository.searchDoctors(params)
  }
}

export type SearchDoctorsModel = SearchDoctors.Model
