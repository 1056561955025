import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 50px 0 50px;
  height: 100%;
`

export const TextContainer = styled.div`
  max-width: 50%;
  margin: 20px 0 50px 0;

  @media (max-width: 800px) {
    max-width: 100%;
    width: 100%;
  }
`

export const Gradient = styled.div`
  width: 500px;
  height: 500px;
  position: absolute;
  background-image: linear-gradient(to top, white 10%, transparent);
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 678px) {
    margin-top: 10px;
    img {
      height: 100%;
    }
  }
`

export const GoBackButtonContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  margin: 20px 50px 0 50px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: center;
`
