import { EligibilityCheck } from 'domain/usecases/eligibility/eligibility-check'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { checkEligibility } from 'repository/graphql/queries'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { EligibilityRepository as IEligibilityRepository } from 'repository/interfaces/eligibility-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'

export class EligibilityRepository implements IEligibilityRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async eligibilityCheck(
    data: EligibilityCheck.Data,
    params: EligibilityCheck.Params
  ): Promise<EligibilityCheck.Model> {
    const apiRepository = this.apiRepository as IApiRepository<Eligibility>

    const query = checkEligibility(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(data, 'data')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as Eligibility
    }
  }
}

type Eligibility = {
  eligible: boolean
  card: string
}
