import { UpdateMedicineAndDiseases } from 'domain/usecases/patient/update-medicine-and-diseases'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteUpdateMedicineAndDiseases
  implements UpdateMedicineAndDiseases
{
  constructor(private readonly patientRepository: PatientRepository) {}

  update(
    params: UpdateMedicineAndDiseases.Params
  ): Promise<UpdateMedicineAndDiseases.Model> {
    return this.patientRepository.updateMedicineAndDiseases(params)
  }
}
