import styled from 'styled-components'

export type UploadListFilesContainerProps = {
  height?: string
  width?: string
  maxHeight?: string
  maxWidth?: string
}

export const UploadListFilesContainer = styled.div<UploadListFilesContainerProps>`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
  gap: 20px;
  margin-bottom: 24px;

  max-height: ${({ maxHeight }) => maxHeight ?? 'auto'};
  max-width: ${({ maxWidth }) => maxWidth ?? 'auto'};
  width: ${({ width }) => width ?? ''};
  height: ${({ height }) => height ?? ''};
`

export const LoadingImage = styled.img`
  height: 50px;
  width: 50px;
  margin-bottom: 24px;
`
