import { LoadReligions } from 'domain/usecases/religion/load-religions'
import { makeAutoObservable } from 'mobx'

type Services = {
  loadReligions: LoadReligions
}

export class ReligionService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadReligions(): Promise<LoadReligions.Model> {
    return this.services.loadReligions.load()
  }
}
