import { makeAutoObservable } from 'mobx'
import type { DeleteDoctorDocument } from 'domain/usecases/document/delete-doctor-document'

type Services = {
  deleteDoctorDocument: DeleteDoctorDocument
}

export class DocumentService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async deleteDoctorDocument(
    params: DeleteDoctorDocument.Params
  ): Promise<DeleteDoctorDocument.Model> {
    await this.services.deleteDoctorDocument.delete(params)
  }
}

export default DocumentService
