import { LoadFavoriteDoctorSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-doctor-order'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadFavoriteDoctorSurgicalOrders
  implements LoadFavoriteDoctorSurgicalOrders
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  load(
    params: LoadFavoriteDoctorSurgicalOrders.Params
  ): Promise<LoadFavoriteDoctorSurgicalOrders.Model> {
    return this.surgicalOrderRepository.loadFavoriteDoctorSurgicalOrders(params)
  }
}
