import { UpdateDoctor } from 'domain/usecases/doctor/update-doctor'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteUpdateDoctor implements UpdateDoctor {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  update(params: UpdateDoctor.Params): Promise<UpdateDoctor.Model> {
    return this.doctorRepository.updateDoctor(params)
  }
}

export type UpdateDoctorModel = UpdateDoctor.Model
