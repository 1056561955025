import { LoadPatientDocuments } from 'domain/usecases/patient/load-patient-documents'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteLoadPatientDocuments implements LoadPatientDocuments {
  constructor(private readonly patientRepository: PatientRepository) {}

  load(
    params?: LoadPatientDocuments.Params
  ): Promise<LoadPatientDocuments.Model> {
    return this.patientRepository.loadPatientDocuments(params)
  }
}

export type LoadPatientDocumentsModel = LoadPatientDocuments.Model
