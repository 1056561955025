import { isNil } from 'lodash'

export default function verifyEmptyObject(obj: any) {
  if (!obj || Object.keys(obj).length === 0) {
    return true
  } else {
    const some = Object.values(obj).some((val) => {
      return isNil(val)
    })
    return !some
  }
}
