import { FinishDoctorSignup } from 'domain/usecases/doctor/finish-doctor-signup'
import { FinishAppOnboarding } from 'domain/usecases/user/finish-app-onboarding'
import { IUserRepositoryRepository } from 'repository/interfaces/user-repository'

export class RemoteFinishAppOnboarding implements FinishAppOnboarding {
  constructor(private readonly userRepository: IUserRepositoryRepository) {}

  finishAppOnboarding(): Promise<FinishAppOnboarding.Model> {
    return this.userRepository.finishAppOnboarding()
  }
}

export type FinishAppOnboardingModel = FinishDoctorSignup.Model
