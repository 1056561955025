import { PatientDocument } from 'common/enum/patient-document'
import { makeAutoObservable } from 'mobx'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'

type FileType = {
  lastModified?: number
  lastModifiedDate?: Date
  name?: string
  size?: number
  type?: string
}

type TypedFile = {
  file: FileType
  type: PatientDocument
}

export class File<ListFileType = unknown> {
  constructor() {
    makeAutoObservable(this)
  }

  file?: FileType = {}
  files?: FileType[] = []
  typedFiles?: TypedFile[] = []
  listFiles?: ListFile<ListFileType>[] = []

  setFile(file: any) {
    return (this.file = file)
  }

  setFiles(file: FileType) {
    this.files?.push(file)
  }

  setTypedFiles(file: TypedFile[]) {
    return (this.typedFiles = file)
  }

  setListFiles(file: ListFile<ListFileType>[]) {
    return (this.listFiles = file)
  }

  clear() {
    this.file = undefined
    this.files = []
    this.typedFiles = []
    this.listFiles = []
  }
}

export default File
