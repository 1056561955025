import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const IntensityItem = styled.div<{ color: string; selected: boolean }>`
  ${({ theme, color, selected }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 87px;
    height: 83px;
    border: 2px solid ${color};
    border-radius: 5px;
    cursor: pointer;
    span {
      color: ${color};
      margin-top: 4px;
      word-spacing: 100vw;
      font-weight: ${theme.font.semiBold};
    }
    svg {
      path {
        fill: ${color};
      }
    }
    ${!!selected && wrapperModifiers.selected(color)}
  `}
`

export const IntensityContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 87px);
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    align-content: center;
    gap: 13px 26px;
    margin-top: 24px;
    font-size: ${theme.font.sizes.xxsmall};
    ${media.greaterThan('medium')`
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    `}
  `}
`

export const SliderContainer = styled.div`
  width: 95%;
  margin: 40px 0;

  .MuiSlider-marked {
    margin: 0;
  }
`

const wrapperModifiers = {
  selected: (color: string) => css`
    background-color: ${color};
    span {
      color: white;
    }
    svg {
      path {
        fill: white;
      }
    }
  `
}
