import moment from 'moment-timezone'

export const dateInputFormat = (date?: string) => {
  if (!date) return ''
  const momentDate = moment(date).add(4, 'hours')
  const days = ('0' + momentDate.date()).slice(-2)
  const month = ('0' + (momentDate.month() + 1).toString()).slice(-2)
  const year = momentDate.year()
  return `${days}/${month}/${year}`
}

interface DateProps {
  milliseconds?: number
  seconds?: number
  minutes?: number
  hours?: number
  date?: number
  days?: number
  months?: number
  years?: number
}
type AddDateProps = Omit<DateProps, 'date'>
type AddDatePropTypes = Array<keyof AddDateProps>
export const addInDate = (date: Date, props: AddDateProps) => {
  const momentParsed = moment(date)

  const keys = Object.keys(props) as AddDatePropTypes

  keys.forEach((key) => {
    if (typeof props[key] === 'number') momentParsed.add(props[key], key)
  })

  return momentParsed.toDate()
}

type SetDateProps = Omit<DateProps, 'days'>
type SetDatePropTypes = Array<keyof SetDateProps>
export const setInDate = (date: Date, props: SetDateProps) => {
  const momentParsed = moment(date)

  const keys: SetDatePropTypes = Object.keys(props) as SetDatePropTypes

  keys.forEach((key) => {
    if (typeof props[key] === 'number') momentParsed.set(key, props[key] || 0)
  })

  return momentParsed.toDate()
}

export const minTimeFromDate = (date: Date): Date => {
  return setInDate(date, {
    milliseconds: 0,
    seconds: 0,
    minutes: 0,
    hours: 0
  })
}

export const maxTimeFromDate = (date: Date): Date => {
  return setInDate(date, {
    milliseconds: 999,
    seconds: 59,
    minutes: 59,
    hours: 23
  })
}

export const isDateBefore = (date: Date, compare: Date) => {
  const parsedDate = minTimeFromDate(date)
  const parsedCompare = maxTimeFromDate(compare)

  return moment(parsedDate).isBefore(parsedCompare)
}

// transforma uma data (30/09/2021) em um objeto Date
export const formatStringToDate = (value: string) => {
  const array = String(value).split('/')

  return setInDate(new Date(), {
    date: +array[0],
    months: +array[1] - 1,
    years: +array[2],
    hours: 0
  })
}

export const isDatesEqual = (date1: Date, date2: Date) => {
  const parsedDate1 = minTimeFromDate(date1)
  const parsedDate2 = minTimeFromDate(date2)

  return moment(parsedDate1).isSame(parsedDate2)
}
