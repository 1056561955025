import { LoadSecretaryPendencies } from 'domain/usecases/secretary/load-secretary-pendencies'
import { SecretaryRepository } from 'repository/interfaces/secretary-repository'

export class RemoteLoadSecretaryPendencies implements LoadSecretaryPendencies {
  constructor(private readonly secretaryRepository: SecretaryRepository) {}

  load(
    params: LoadSecretaryPendencies.Params
  ): Promise<LoadSecretaryPendencies.Model> {
    return this.secretaryRepository.loadSecretaryPendencies(params)
  }
}

export type LModel = LoadSecretaryPendencies.Model
