import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'

import { DocumentRepositoryImpl } from 'repository/repositories/document/document-repository-impl'
import DocumentService from 'presentation/contexts/services/document-service'
import { RemoteDeleteDoctorDocument } from 'service/usecases/delete-doctor-document/remote-delete-doctor-document'

export default function makeDocumentService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const documentRepository = new DocumentRepositoryImpl(apiRepository)

  const deleteDoctorDocument = new RemoteDeleteDoctorDocument(
    documentRepository
  )

  return new DocumentService({
    deleteDoctorDocument
  })
}
