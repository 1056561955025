import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemotePatientUpdateInfo implements UpdatePatientInfo {
  constructor(private readonly patientRepository: PatientRepository) {}

  update(params: UpdatePatientInfo.Params): Promise<UpdatePatientInfo.Model> {
    return this.patientRepository.updatePatientInfo(params)
  }
}

export type PatientUpdateInfoModel = UpdatePatientInfo.Model
