import { ChangePassword } from 'domain/usecases/authentication/change-password'
import { AuthenticationRepository } from 'repository/interfaces/authentication-repository'

export class RemoteChangePassword implements ChangePassword {
  constructor(
    private readonly authenticationRepository: AuthenticationRepository
  ) {}
  changePassword(params: ChangePassword.Params): Promise<ChangePassword.Model> {
    return this.authenticationRepository.changePassword(params)
  }
}

export type ChangePasswordModel = ChangePassword.Model
