import { ValidateRegisteredPatientInDb } from 'domain/usecases/patient/validate-registered-patient-in-db'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteValidateRegisteredPatientInDb
  implements ValidateRegisteredPatientInDb
{
  constructor(private readonly patientRepository: PatientRepository) {}

  async validate(
    params: ValidateRegisteredPatientInDb.Params
  ): Promise<ValidateRegisteredPatientInDb.Model> {
    return this.patientRepository.validateRegisteredPatientInDb(params)
  }
}
