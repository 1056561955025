import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadDoctor implements LoadDoctor {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(params?: LoadDoctor.Params): Promise<LoadDoctor.Model> {
    return this.doctorRepository.loadDoctor(params)
  }
}

export type LoadDoctorModel = LoadDoctor.Model
