import { VerifyLoginClient } from 'domain/usecases/authentication/verify-client'
import { AuthenticationRepository } from 'repository/interfaces/authentication-repository'

export class RemoteVerifyLoginClient implements VerifyLoginClient {
  constructor(
    private readonly authenticationRepository: AuthenticationRepository
  ) {}

  async validate(
    params: VerifyLoginClient.Params
  ): Promise<VerifyLoginClient.Model> {
    const validation = await this.authenticationRepository.verifyLoginClient(
      params
    )
    if (!validation) {
      throw new Error()
    } else {
      return true
    }
  }
}

export type VerifyLoginClientModel = VerifyLoginClient.Model
