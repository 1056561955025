import theme from 'presentation/styles/theme'
import React, { SelectHTMLAttributes } from 'react'

import * as S from './styles'

export type SelectFieldProps = {
  onInputChange?: (value: string) => void
  label?: string
  initialValue?: string
  disabled?: boolean
  outline?: boolean
  error?: string | false | string[]
  items?: { value: string | ReadonlyArray<string> | number; label: string }[]
  bgColor?: 'mainBg' | 'white'
  placeholder?: string
  labelColor?: keyof typeof theme.colors
} & SelectHTMLAttributes<HTMLSelectElement>

export default function SelectField({
  onInputChange,
  label,
  disabled,
  error,
  name,
  style,
  items,
  outline,
  labelColor,
  bgColor = 'white',
  required = false,
  placeholder = 'Selecionar',
  ...props
}: SelectFieldProps) {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.currentTarget.value
    !!onInputChange && onInputChange(newValue)
    !!props.onChange && props.onChange(e)
  }

  return (
    <S.Wrapper style={style} disabled={disabled} error={!!error}>
      {!!label && (
        <S.Label htmlFor={name} labelColor={labelColor}>
          {label}
          {required ? <S.Asterisk>*</S.Asterisk> : ''}
        </S.Label>
      )}
      <S.Select
        onChange={onChange}
        name={name}
        disabled={disabled}
        data-testid="select"
        bgColor={bgColor}
        id={name}
        outline={outline}
        {...props}
      >
        <S.Option value="" disabled style={{ display: 'none' }}>
          {placeholder}
        </S.Option>
        {items?.map((item) => (
          <S.Option
            key={item.value + '-' + item.label}
            value={item.value}
            data-testid={`select-options-${item.value}`}
          >
            {item.label}
          </S.Option>
        ))}
      </S.Select>
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  )
}
