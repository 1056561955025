import React from 'react'
import * as S from './styles'
import { ListFile, UploadFileCanProps, UploadListFile } from './UploadListFile'
import LoadingGif from 'presentation/assets/icons/loading.gif'
import DocInput from '../../FileInput'
import { getThumbnail } from '../Functions'
import { UploadListErrors } from './UploadListErrors'

export type UploadFilesCanProps = {
  canAdd?: boolean
} & UploadFileCanProps

type AddFileType = {
  file: File
  thumbnail: string
}

export type UploadListFileProps<ListFileType = unknown> = {
  files: ListFile<ListFileType>[]
  loading?: boolean
  can: UploadFilesCanProps
  onUpdate: (
    listFile: ListFile<ListFileType>,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  onDelete: (listFile: ListFile<ListFileType>) => void
  onDownload: (listFile: ListFile<ListFileType>) => void
  listContainerProps?: S.UploadListFilesContainerProps
  acceptFiles?: string
  onAdd: (
    files: AddFileType[],
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  addMultiple?: boolean
  errors?: string[]
}

export function UploadListFiles<ListFileType = unknown>({
  files,
  listContainerProps,
  can,
  onUpdate,
  loading,
  onDelete,
  onDownload,
  acceptFiles,
  onAdd,
  addMultiple = true,
  errors
}: UploadListFileProps<ListFileType>) {
  if (loading) return <S.LoadingImage src={LoadingGif} />

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files: AddFileType[] = await Promise.all(
        [...event.target.files].map(async (file) => ({
          file: file,
          thumbnail: await getThumbnail(file)
        }))
      )
      onAdd(files, event)
    }
  }

  return (
    <>
      {errors && errors.length > 0 && <UploadListErrors errors={errors} />}
      <S.UploadListFilesContainer {...listContainerProps}>
        {files.map((file) => (
          <UploadListFile
            listFile={file}
            allCan={can}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onDownload={onDownload}
            key={`page-${file.page}`}
            acceptFiles={acceptFiles}
          />
        ))}
        {can.canAdd && (
          <DocInput
            style={{ height: '140px', width: '140px' }}
            name="files"
            text="Adicionar documento"
            onChange={onChange}
            fileName={''}
            notShowFileName
            data-testid="add-new-document"
            multiple={addMultiple}
            inputProps={{ value: [] }}
            acceptFiles={acceptFiles}
            ignoreOnInput
          />
        )}
      </S.UploadListFilesContainer>
    </>
  )
}
