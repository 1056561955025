import styled from 'styled-components'

export const HelpHeader = styled.div`
  width: 100%;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.font.sizes.large};
  font-weight: ${({ theme }) => theme.font.black};
  font-family: ${({ theme }) => theme.font.family};
`

export const HelpBody = styled.div`
  width: 100%;
  height: auto;
  text-align: justify;
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-family: ${({ theme }) => theme.font.family};
  margin-top: 11px;
  margin-bottom: 5px;
  line-height: 20px;
  strong {
    font-weight: ${({ theme }) => theme.font.black};
  }
`

export const Backdrop = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  z-index: ${({ theme }) => theme.layers.overlay};
  pointer-events: auto;
`
