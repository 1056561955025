import { LoadDistrictsByDescription } from 'domain/usecases/address/load-districts-by-description'
import { AddressRepository } from 'repository/interfaces/address-repository'

export class RemoteLoadDistrictsByDescription
  implements LoadDistrictsByDescription
{
  constructor(private readonly addressRepository: AddressRepository) {}

  load(
    params: LoadDistrictsByDescription.Params
  ): Promise<LoadDistrictsByDescription.Model> {
    return this.addressRepository.loadDistricts(params)
  }
}
