import { lighten } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const List = styled.div`
  ${({ theme }) => css`
    background-color: white;
    border-radius: ${theme.border.radius.small};
    margin-top: 35px;
    li {
      flex-direction: row-reverse;
    }

    img.icon {
      width: 28px;
    }
  `}
`

export const AddReport = styled.p`
  ${({ theme }) => css`
    cursor: pointer;
    position: relative;
    letter-spacing: 0.15px;
    margin-top: 15px;
    padding-left: 16px;
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.gray};
    padding-bottom: 16px;
    border-bottom: 1px solid ${lighten(theme.colors.lightGray, 0.2)};
  `}
`
