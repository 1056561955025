import { LoadPatientHideContact } from 'domain/usecases/patient/load-patient-hide-contact'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteLoadPatientHideContact implements LoadPatientHideContact {
  constructor(private readonly patientRepository: PatientRepository) {}

  load(
    params: LoadPatientHideContact.Params
  ): Promise<LoadPatientHideContact.Model> {
    return this.patientRepository.loadPatientHideContact(params)
  }
}
