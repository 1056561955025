import { SurgicalOrderCheckin } from 'domain/usecases/surgical-order/checkin'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteCheckinSurgicalOrder implements SurgicalOrderCheckin {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}
  checkin(
    params: SurgicalOrderCheckin.Params
  ): Promise<SurgicalOrderCheckin.Model> {
    return this.surgicalOrderRepository.checkin(params)
  }
}
