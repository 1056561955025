import { GetPasswordRecoveryCode } from 'domain/usecases/authentication/get-password-recovery-code'
import { AuthenticationRepository } from 'repository/interfaces/authentication-repository'
export class RemoteGetPasswordRecoveryCode implements GetPasswordRecoveryCode {
  constructor(
    private readonly authenticationRepository: AuthenticationRepository
  ) {}
  getPasswordRecoveryCode(
    params: GetPasswordRecoveryCode.Params
  ): Promise<GetPasswordRecoveryCode.Model> {
    return this.authenticationRepository.getPasswordRecoveryCode(params)
  }
}

export type GetPasswordRecoveryCodeModel = GetPasswordRecoveryCode.Model
