import { AddHospitalizationService } from 'domain/usecases/hospitalization/add-hospitalization-service'
import { HospitalizationRepository } from 'repository/interfaces/hospitalization-repository'

export class RemoteAddHospitalizationService
  implements AddHospitalizationService
{
  constructor(
    private readonly hospitalizationRepository: HospitalizationRepository
  ) {}

  add(
    params: AddHospitalizationService.Params
  ): Promise<AddHospitalizationService.Model> {
    return this.hospitalizationRepository.addHospitalizationService(params)
  }
}
