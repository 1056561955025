import NoImage from 'presentation/assets/images/no-image.jpg'
import { getPdfThumbnail } from 'presentation/utils/get-pdf-thumbnail'

export const getThumbnail = async (file: File) => {
  const isImage = file.type?.startsWith('image')
  if (isImage) {
    return URL.createObjectURL(file)
  }
  const isPdf = file.type?.endsWith('pdf')
  if (isPdf) return getPdfThumbnail(file)
  return NoImage
}
