import { UserAlreadyRegistered } from 'domain/usecases/user/user-already-registered'
import { UserRepository } from 'repository/repositories/user/user-repository'

export class RemoteUserAlreadyRegistered implements UserAlreadyRegistered {
  constructor(private readonly userRepository: UserRepository) {}

  execute(params: UserAlreadyRegistered.Params): Promise<boolean> {
    return this.userRepository.userAlreadyRegistered(params)
  }
}
