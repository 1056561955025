import { GetSecretaryByCpf } from 'domain/usecases/secretary/get-secretary-by-cpf'
import { LoadSecretaryPendencies } from 'domain/usecases/secretary/load-secretary-pendencies'
import { LoadSecretarySurgicalPendenciesCount } from 'domain/usecases/secretary/load-secretary-surgical-pendencies-count'
import { makeAutoObservable } from 'mobx'

type Services = {
  loadSecretaryPendencies: LoadSecretaryPendencies
  countSurgicalPendencies: LoadSecretarySurgicalPendenciesCount
  getSecretaryByCpf: GetSecretaryByCpf
}

export class SecretaryService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadSecretaryPendencies(
    params: LoadSecretaryPendencies.Params
  ): Promise<LoadSecretaryPendencies.Model> {
    return this.services.loadSecretaryPendencies.load(params)
  }

  async countSurgicalPendencies(
    params?: LoadSecretarySurgicalPendenciesCount.Params
  ): Promise<LoadSecretarySurgicalPendenciesCount.Model> {
    return this.services.countSurgicalPendencies.count(params)
  }

  async getSecretaryByCpf(
    params: GetSecretaryByCpf.Params
  ): Promise<GetSecretaryByCpf.Model> {
    return this.services.getSecretaryByCpf.execute(params)
  }
}

export default SecretaryService
