import { AddFavoriteSurgicalOrder } from 'domain/usecases/doctor/add-favorite-surgical-order'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteAddFavoriteSurgicalOrder
  implements AddFavoriteSurgicalOrder
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  add(
    params: AddFavoriteSurgicalOrder.Params
  ): Promise<AddFavoriteSurgicalOrder.Model> {
    return this.doctorRepository.addFavoriteSurgicalOrder(params)
  }
}
