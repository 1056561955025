import { DeleteFavoritedSurgicalOrder } from 'domain/usecases/surgical-order/delete-favorited-surgical-order'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteDeleteFavoritedSurgicalOrder
  implements DeleteFavoritedSurgicalOrder
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  delete(
    params: DeleteFavoritedSurgicalOrder.Params
  ): Promise<DeleteFavoritedSurgicalOrder.Model> {
    return this.surgicalOrderRepository.deleteFavoritedSurgicalOrder(params)
  }
}
