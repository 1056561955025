import { AccompanyingRepository } from 'repository/interfaces/accompanying-repository'
import { UpdateAccompanying } from 'domain/usecases/accompanying/update-accompanying'

export class RemoteUpdateAccompanying implements UpdateAccompanying {
  constructor(
    private readonly accompanyingRepository: AccompanyingRepository
  ) {}

  update(params: UpdateAccompanying.Params): Promise<UpdateAccompanying.Model> {
    return this.accompanyingRepository.updateAccompanying(params)
  }
}
