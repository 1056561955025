import { LoadDocumentExternalCertifications } from 'domain/usecases/doctor/load-documents-external-certifications'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadDocumentExternalCertifications
  implements LoadDocumentExternalCertifications
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(
    params: LoadDocumentExternalCertifications.Params
  ): Promise<LoadDocumentExternalCertifications.Model> {
    return this.doctorRepository.loadDocumentsExternalCertifications(params)
  }
}
