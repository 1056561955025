import { DateLocales } from 'common/enum/date-locales'

const getDateFormattedByLocale = (
  date: string | Date | undefined,
  locale: DateLocales,
  include_time = false
) => {
  try {
    if (!date) {
      return ''
    }
    const dateToFormat = new Date(date)
    return include_time
      ? `${dateToFormat.toLocaleDateString(
          locale
        )} ${dateToFormat.toLocaleTimeString(locale, {
          hour: '2-digit',
          minute: '2-digit'
        })}`
      : new Date(date).toLocaleDateString(locale)
  } catch (err) {
    return ''
  }
}

export default getDateFormattedByLocale
