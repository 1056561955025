import styled from 'styled-components'

export const ContentText = styled.div`
  padding: 16px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 87px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightestGray};
  font-weight: ${({ theme }) => theme.font.normal};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`

export const HeaderText = styled.span`
  padding: 12px 0;
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
  }

  & > div:first-child {
    padding: 25px 0;
  }
`
