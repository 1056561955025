import { CardWrapper } from 'presentation/shared/components/Card'
import styled from 'styled-components'

export const Header = styled.header``
export const CardItem = styled(CardWrapper)`
  height: 60px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: -2px 3px 5px -4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;

  &:first-of-type {
    grid-column: span 2;
    width: 100%;
  }
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 11px;
  font-size: 14px;
  margin: 24px 0;
`
