import styled from 'styled-components'

export const UploadTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
`

export const TitleButton = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background: transparent;
  cursor: pointer;

  img {
    height: 22px;
    margin-right: 8px;
  }

  label {
    font-size: ${({ theme }) => theme.font.sizes.small};
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`
