import { AccountModel } from 'domain/entities/account-model'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'

export const setCurrentUserAdapter = (account: AccountModel): void => {
  makeLocalStorageAdapter().set('account', account)
}

export const getCurrentUserAdapter = (): AccountModel => {
  return makeLocalStorageAdapter().get('account')
}

export const removeCurrentUserAdapter = (): void => {
  makeLocalStorageAdapter().set('account')
}
