import styled from 'styled-components'
import { lighten } from 'polished'

export const List = styled.div`
  background-color: white;
  border-radius: ${({ theme }) => theme.border.radius.small};
  li {
    flex-direction: row-reverse;
  }

  img.icon {
    width: 28px;
  }
`

export const MoreExamReportButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 20px;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius.small};
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => lighten(0.68, theme.colors.gray)};
  }
`
