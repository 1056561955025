import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { AddressService } from 'presentation/contexts/services/address-service'
import { AddressRepository } from 'repository/repositories/address/address-repository'
import { RemoteLoadDistrictsByDescription } from 'service/usecases/load-districts-by-description/remote-load-districts-by-description'
import { RemoteLoadCitiesFromDistrict } from 'service/usecases/load-cities-from-district/remote-load-cities-from-district'
import { RemoteAddAddress } from 'service/usecases/add-address/remote-add-address'
import { RemoteLoadAddress } from 'service/usecases/load-address/remote-load-address'

export default function makeAddressService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const addressRepository = new AddressRepository(apiRepository)
  const loadDistrictsByDecription = new RemoteLoadDistrictsByDescription(
    addressRepository
  )
  const loadCitiesFromDistrict = new RemoteLoadCitiesFromDistrict(
    addressRepository
  )
  const addAddress = new RemoteAddAddress(addressRepository)
  const loadAddress = new RemoteLoadAddress(addressRepository)

  return new AddressService({
    loadDistrictsByDecription,
    loadCitiesFromDistrict,
    addAddress,
    loadAddress
  })
}
