import { LoadSurgeryDetails } from 'domain/usecases/surgery/load-surgery-details'
import { SurgeryRepository } from 'repository/interfaces/surgery-repository'

export class RemoteLoadSurgeryDetails implements LoadSurgeryDetails {
  constructor(private readonly patientRepository: SurgeryRepository) {}

  load(params: LoadSurgeryDetails.Params): Promise<LoadSurgeryDetails.Model> {
    return this.patientRepository.loadDetails(params)
  }
}

export type LoadSurgeryDetailsModel = LoadSurgeryDetails.Model
