import React, { useEffect } from 'react'
import { Snackbar, Button } from '@material-ui/core'
import * as serviceWorker from 'main/config/serviceWorkerRegistration'

type Props = {
  children: React.ReactNode
}

const ServiceWorkerWrapper = ({ children }: Props) => {
  const [showReload, setShowReload] = React.useState(false)
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null)

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true)
    setWaitingWorker(registration.waiting)
  }

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate })
  }, [])

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' })
    setShowReload(false)
    window.location.reload()
  }

  return (
    <>
      <Snackbar
        open={showReload}
        message="Nova versão disponível, clique aqui para atualizar o sistema! (certifique-se de fechar outras abas do sistema)"
        onClick={reloadPage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <Button color="inherit" size="small" onClick={reloadPage}>
            Recarregar
          </Button>
        }
      />
      {children}
    </>
  )
}

export default ServiceWorkerWrapper
