import styled from 'styled-components'

export const Wrapper = styled.div`
  h1 {
    padding: 0 24px;
  }
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  p {
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    cursor: pointer;
  }
`

export const Content = styled.ul`
  flex: 1;
  width: 100%;
  padding: 0 32px;
  margin-top: 18px;
`

export const PendencyWrapper = styled.li`
  list-style: none;
  background-color: white;
  width: 100%;
  height: auto;
  border-radius: 20px;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 20px;
  }

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    h4 {
      font-size: 16px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.primary};
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: calc(16px * 2);
    }

    span {
      margin-top: -4px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    justify-content: flex-end;
    flex: 1;

    & > div {
      display: flex;
      align-items: center;
      height: 30%;
      h6 {
        font-size: 12px;
        font-weight: 500;
        margin-right: 6px;
        color: ${({ theme }) => theme.colors.primary};
      }
      span {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
`

export const SearchContainer = styled.div`
  display: grid;
  grid-template-columns: auto 54px;
  gap: 10px;

  input {
    margin: 0;
  }

  & > div:first-child {
    div {
      svg {
        padding: 0;
        margin: 0;
      }
    }
  }

  & > div:last-child {
    height: 54px;
    width: 54px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
  }
`

export const PendenciesTabContainer = styled.div`
  margin-top: 12px;
`

export const LoadMoreButton = styled.div`
  margin-top: 20px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.gray};
  }
  svg {
    transform: rotate(90deg);
    margin-left: 10px;
    margin-right: 5px;
  }
`

export const LoadingMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;

  img {
    height: 40px;
  }
`
export const NoPendenciesLabel = styled.label`
  display: block;
  text-align: center;
  margin-top: 12px;
`
