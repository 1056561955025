import styled from 'styled-components'

export const Wrapper = styled.form`
  height: 100%;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: auto;

  h2 {
    font-weight: 400;
  }
`

export const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
`

export const Patient = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  p {
    margin-left: 8px;
  }
`

export const PatientInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.font.sizes.xxsmall};

  strong {
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.colors.primary};
  }

  label {
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.colors.gray};
  }

  & + & {
    margin-top: 4px;
  }
`

export const Content = styled.div`
  display: flex;
  flex: 1 0;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;

  textarea {
    width: 100%;
    margin-top: 27px;
    margin-bottom: 10px;
  }

  h2 {
    font-weight: 400;
  }
`

export const RadioWrapper = styled.div`
  & div {
    margin-top: 20px;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
