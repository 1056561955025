import { ReactElement } from 'react'

type ReportList<T> = {
  title: string | ReactElement
} & T

export function sortReports<T>(unsortedReportList: ReportList<T>[]) {
  return unsortedReportList.sort((firstValue, secondValue) => {
    const firstReportNumber = Number(String(firstValue.title)?.split(' ')[1])
    const secondReportNumber = Number(String(secondValue.title)?.split(' ')[1])
    return firstReportNumber - secondReportNumber
  })
}
