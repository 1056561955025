import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'

export const setCurrentCrmoFilterAdapter = (filter: CrmoFilter): void => {
  makeLocalStorageAdapter().set('filter', filter)
}

export const getCurrentCrmoFilterAdapter = (): CrmoFilter => {
  return makeLocalStorageAdapter().get('filter')
}

export interface CrmoFilter {
  start?: string
  end?: string
  label?: string
}
