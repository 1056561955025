import styled from 'styled-components'

export const UploadActionsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1em;
  justify-content: end;
  align-items: center;
  flex-direction: row-reverse;

  button {
    max-width: 220px;
  }
`
