import { AuthenticationRepository } from 'repository/interfaces/authentication-repository'
import { Authentication } from 'domain/usecases/authentication/authentication'

export class RemoteAuthentication implements Authentication {
  constructor(
    private readonly authenticationRepository: AuthenticationRepository
  ) {}

  auth(params: Authentication.Params): Promise<Authentication.Model> {
    return this.authenticationRepository.auth(params)
  }
}

export type AuthenticationModel = Authentication.Model
