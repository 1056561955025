import { UpdatePassword } from 'domain/usecases/user/update-password'
import { UserRepository } from 'repository/repositories/user/user-repository'

export class RemoteUpdateUserPassword implements UpdatePassword {
  constructor(private readonly userRepository: UserRepository) {}

  updatePassword(params: UpdatePassword.Params): Promise<UpdatePassword.Model> {
    return this.userRepository.updatePassword(params)
  }
}
