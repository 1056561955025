import styled, { css } from 'styled-components'

export const Wrapper = styled.div``

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: 18px auto 10px;
  align-items: center;
  cursor: pointer;
  height: 66px;

  & + & {
    border-top: 1px solid #dddddd;
  }
`

export const MenuButton = styled.button`
  background: transparent;
  border: none;
  position: relative;
`

export const AccompanyingInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.lightGray};

    div {
      margin-left: 14px;
    }

    p {
      color: ${theme.colors.gray};
    }
  `}
`

export const Options = styled.div`
  display: flex;
  direction: row;
  align-items: center;
  justify-content: flex-end;

  > div {
    margin-right: 8px;
  }
`

export const Image = styled.img`
  color: #c53030;
  width: 14px;
  height: 14px;
`
