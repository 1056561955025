import { AddAddress } from 'domain/usecases/address/add-address'
import { AddressRepository } from 'repository/interfaces/address-repository'

export class RemoteAddAddress implements AddAddress {
  constructor(private readonly addressRepository: AddressRepository) {}

  add(params: AddAddress.Params): Promise<AddAddress.Model> {
    return this.addressRepository.addAddress(params)
  }
}
