import React, { HtmlHTMLAttributes } from 'react'

import * as S from './styles'

export type SheetModalProps = {
  isOpen: boolean
  isDraggable?: boolean
  backdrop?: 'true' | 'false'
  marginbottom?: string
  size?: 'small' | 'medium' | number
  onClose: () => void
} & HtmlHTMLAttributes<HTMLElement>

export default function SheetModal({
  isOpen,
  onClose,
  isDraggable,
  backdrop = 'true',
  size,
  marginbottom,
  ...props
}: SheetModalProps) {
  const snapPoints = typeof size === 'number' ? [size] : []

  return (
    <S.Sheet
      disableDrag={!isDraggable}
      isOpen={isOpen}
      onClose={onClose}
      onCloseEnd={onClose}
      backdrop={backdrop}
      marginbottom={marginbottom}
      snapPoints={snapPoints}
    >
      <S.Sheet.Container style={props.style}>
        <S.Sheet.Content style={props.style}>{props.children}</S.Sheet.Content>
      </S.Sheet.Container>

      <S.Sheet.Backdrop onTap={onClose} data-testid={'sheet-modal-backdrop'} />
    </S.Sheet>
  )
}
