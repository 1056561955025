import { ValidatePatientMotherAndBirthday } from 'domain/usecases/patient/validate-patient-mother-and-birthday'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteValidatePatientMotherAndBirthday
  implements ValidatePatientMotherAndBirthday
{
  constructor(private readonly patientRepository: PatientRepository) {}

  async validate(
    params: ValidatePatientMotherAndBirthday.Params
  ): Promise<ValidatePatientMotherAndBirthday.Model> {
    return this.patientRepository.validatePatientMotherAndBirthday(params)
  }
}
