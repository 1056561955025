import { AddAddress } from 'domain/usecases/address/add-address'
import { LoadAddress } from 'domain/usecases/address/load-address'
import { LoadCitiesFromDistrict } from 'domain/usecases/address/load-cities-from-district'
import { LoadDistrictsByDescription } from 'domain/usecases/address/load-districts-by-description'
import { makeAutoObservable } from 'mobx'

type Services = {
  loadDistrictsByDecription: LoadDistrictsByDescription
  loadCitiesFromDistrict: LoadCitiesFromDistrict
  addAddress: AddAddress
  loadAddress: LoadAddress
}

export class AddressService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadDistricts(
    params: LoadDistrictsByDescription.Params
  ): Promise<LoadDistrictsByDescription.Model> {
    return this.services.loadDistrictsByDecription.load(params)
  }

  async loadCitiesFromDistrict(
    params: LoadCitiesFromDistrict.Params
  ): Promise<LoadCitiesFromDistrict.Model> {
    return this.services.loadCitiesFromDistrict.load(params)
  }

  async addAddress(params: AddAddress.Params): Promise<AddAddress.Model> {
    return this.services.addAddress.add(params)
  }

  async loadUserAddress(): Promise<LoadAddress.Model> {
    return this.services.loadAddress.load()
  }
}
