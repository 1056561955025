import { AddSurgicalOrder } from 'domain/usecases/surgical-order/add-surgical-order'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemotePatientAddSurgicalOrder implements AddSurgicalOrder {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  add(params: AddSurgicalOrder.Params): Promise<AddSurgicalOrder.Model> {
    return this.surgicalOrderRepository.addPatientSurgicalOrder(params)
  }
}
