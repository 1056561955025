import React from 'react'
import * as S from './styles'
import { ReactComponent as WhatsAppIcon } from 'presentation/assets/icons/whatsapp-icon.svg'

type Props = {
  handleRedirect(): any
}

const WhatsAppButton = ({ handleRedirect }: Props) => {
  return (
    <S.WppButton onClick={handleRedirect}>
      <WhatsAppIcon fill="#03601C" color="#03601C" />
      <S.TitleButton>Ir para atendimento</S.TitleButton>
    </S.WppButton>
  )
}

export default WhatsAppButton
