import { ConfirmMergeDocuments } from 'domain/usecases/surgical-order/confirm-merge-documents'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteConfirmMergeDocuments implements ConfirmMergeDocuments {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  confirm(
    params: ConfirmMergeDocuments.Params
  ): Promise<ConfirmMergeDocuments.Model> {
    return this.surgicalOrderRepository.confirmMergeDocuments(params)
  }
}
