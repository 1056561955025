import { LoadRecommendedDoctors } from 'domain/usecases/doctor/load-recommended-doctors'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadRecommendedDoctors implements LoadRecommendedDoctors {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(
    params: LoadRecommendedDoctors.Params
  ): Promise<LoadRecommendedDoctors.Model> {
    return this.doctorRepository.loadRecommendedDoctors(params)
  }
}
