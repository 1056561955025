import styled from 'styled-components'

type ColumnProps = { cols: number }

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

export const Label = styled.div<ColumnProps>`
  display: block;
  text-align: center;
  border-radius: 12px 12px 0px 0px;
  display: grid;
  width: 100%;
  grid-template-columns: ${({ cols }) => 'repeat(' + cols + ', 1fr)'};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightestGreen};
  font-size: 16px;
`

export const Box = styled.div<ColumnProps>`
  background-color: ${({ theme }) => theme.colors.white};
  grid-template-columns: ${({ cols }) => 'repeat(' + cols + ', 1fr)'};
  border-radius: 0px 0px 12px 12px;
  display: grid;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 16px;
`
