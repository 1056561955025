import { ExamModel } from 'domain/entities/exam-model'
import { makeAutoObservable } from 'mobx'

export class ExamContext {
  constructor() {
    makeAutoObservable(this)
  }

  patientExams: ExamModel[] = []

  getPatientExams() {
    return this.patientExams
  }

  setPatientExam(exams: ExamModel[]) {
    return (this.patientExams = exams)
  }
}

export default ExamContext
