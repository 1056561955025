import { LoadUser } from 'domain/usecases/user/load-user'
import { UserRepository } from 'repository/repositories/user/user-repository'

export class RemoteLoadUser implements LoadUser {
  constructor(private readonly userRepository: UserRepository) {}

  load(params: LoadUser.Params): Promise<LoadUser.Model> {
    return this.userRepository.load(params)
  }
}

export type LoadUserModel = LoadUser.Model
