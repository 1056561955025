import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import HealthInsurancePlansService from 'presentation/contexts/services/health-insurance-plans'
import { ApiRepository } from 'repository/api-repository'
import { HealthInsuranceRepository } from 'repository/repositories/health-insurance/health-insurance-repository'
import { RemoteLoadHealthInsurance } from 'service/usecases/load-health-insurance/remote-load-health-insurance'

export default function makeHealthInsurancePlansService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const healthInsuranceRepository = new HealthInsuranceRepository(apiRepository)

  const loadHealthInsurancePlans = new RemoteLoadHealthInsurance(
    healthInsuranceRepository
  )

  return new HealthInsurancePlansService({
    loadHealthInsurancePlans
  })
}
