import { ReactElement } from 'react'

type ReportList = {
  title: string | ReactElement
}

export function getNextReportIndex(reportList: ReportList[]) {
  const reportsNumber = reportList
    .map(({ title }) => Number(`${title}`.split(' ')[1]))
    .sort((firstValue, secondValue) => firstValue - secondValue)
  const indexEmpty = reportsNumber.findIndex(
    (number, index) => number - 1 !== index
  )
  return (indexEmpty === -1 ? reportsNumber.length : indexEmpty) + 1
}
