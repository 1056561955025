import { HealthInsuranceRepository } from 'repository/interfaces/health-insurance-repository'
import { LoadHealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'

export class RemoteLoadHealthInsurancePlans
  implements LoadHealthInsurancePlans
{
  constructor(
    private readonly healthInsuranceRepository: HealthInsuranceRepository
  ) {}

  load(
    params: LoadHealthInsurancePlans.Params
  ): Promise<LoadHealthInsurancePlans.Model> {
    return this.healthInsuranceRepository.loadHealthInsurancePlans(params)
  }
}
