export default function getTelephoneExtensions(hospitalName: string) {
  const santoAgostinho = [
    {
      phoneExtension: '9113',
      area: 'SND'
    },
    {
      phoneExtension: '9106',
      area: 'Rouparia'
    },
    {
      phoneExtension: '9180',
      area: 'Recepção / Internação'
    },
    {
      phoneExtension: '9196',
      area: 'SAC'
    },
    {
      phoneExtension: '9219',
      area: 'Estacionamento'
    },
    {
      phoneExtension: '9192',
      area: 'Achados e Perdidos'
    }
  ]
  const betim = [
    {
      phoneExtension: '2171',
      area: 'SND'
    },
    {
      phoneExtension: '2199',
      area: 'Rouparia'
    },
    {
      phoneExtension: '2102',
      area: 'Recepção / Internação'
    },
    {
      phoneExtension: '2222',
      area: 'SAC'
    },
    {
      phoneExtension: '2283',
      area: 'Estacionamento'
    },
    {
      phoneExtension: '2273',
      area: 'Achados e Perdidos'
    }
  ]

  const contorno = [
    {
      phoneExtension: '7142',
      area: 'SND'
    },
    {
      phoneExtension: '7294',
      area: 'Rouparia'
    },
    {
      phoneExtension: '7256',
      area: 'Recepção / Internação'
    },
    {
      phoneExtension: '7298',
      area: 'SAC'
    },
    {
      phoneExtension: '7888',
      area: 'Estacionamento'
    },
    {
      phoneExtension: '7888',
      area: 'Achados e Perdidos'
    }
  ]

  if (hospitalName.toLocaleLowerCase().includes('agostinho'))
    return santoAgostinho
  if (hospitalName.toLocaleLowerCase().includes('betim')) return betim
  if (hospitalName.toLocaleLowerCase().includes('contorno')) return contorno
}
