import CheckIcon from 'presentation/assets/icons/big-check.svg'
import DownloadIcon from 'presentation/assets/icons/download.svg'
import ArrowIcon from 'presentation/assets/icons/rounded-arrow.svg'
import AlertIcon from 'presentation/assets/icons/alert.svg'

export const setDocListIcon = (
  documentType: string[],
  uploadable: boolean,
  documents: any[]
) => {
  const doc = documentType.some((type) => {
    return documents.find(
      (document) => document.type.toLowerCase() === type.toLowerCase()
    )
  })
  if (doc) {
    if (doc === uploadable) {
      return CheckIcon
    }
    return DownloadIcon
  }
  return uploadable ? ArrowIcon : AlertIcon
}
