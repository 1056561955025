import { Hospital } from 'domain/entities/hospital-model'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'

export const setCurrentHospitalAdapter = (hospital: Hospital): void => {
  makeLocalStorageAdapter().set('hospital', hospital)
}

export const getCurrentHospitalAdapter = (): Hospital => {
  return makeLocalStorageAdapter().get('hospital')
}
