import styled, { css, DefaultTheme } from 'styled-components'
import { lighten } from 'polished'

import { TextAreaProps } from '.'

type IconPositionProps = Pick<
  TextAreaProps,
  'bgColor' | 'outline' | 'outlineColor'
>

type WrapperProps = Pick<TextAreaProps, 'disabled'> & { error?: boolean }

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const Input = styled.textarea<IconPositionProps>`
  color: ${({ theme }) => theme.colors.gray};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  line-height: ${({ theme }) => theme.font.sizes.large};
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 12px;
  background: ${({ theme, bgColor }) => theme.colors[bgColor!]};
  border: ${({ outline, outlineColor }) =>
    outline ? '1px solid' + outlineColor : 'none'};
  resize: none;
  margin-top: 12px;
  outline: none;
  border-radius: ${({ theme }) => theme.border.radius.small};
  width: 100%;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: solid;
    outline-width: 2px;
    outline-color: ${({ theme }) => theme.colors.primary} !important;
  }
`

export const Label = styled.label`
  font-size: ${({ theme }) => theme.font.sizes.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`

export const Icon = styled.div<IconPositionProps>`
  display: flex;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 12px;
  cursor: pointer;
  & > svg {
    height: 54px;
  }
`

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.lightRed};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`

const wrapperModifiers = {
  error: (theme: DefaultTheme) => css`
    ${Input} {
      border-color: ${theme.colors.lightRed};
      border-width: 1px;
      border-style: solid;
    }
    ${Icon} {
      color: ${theme.colors.lightRed};
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    ${Label},
    ${Input},
    ${Icon} {
      &::placeholder {
        color: currentColor;
      }
    }
    ${Input} {
      background-color: ${lighten(0.3, theme.colors.lightGray)};
    }
  `
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, error }) => error && wrapperModifiers.error(theme)}
  ${({ theme, disabled }) => disabled && wrapperModifiers.disabled(theme)}
`

export const Asterisk = styled.span`
  font-size: ${({ theme }) => theme.font.sizes.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.lightRed};
  cursor: pointer;
`
