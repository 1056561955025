import styled from 'styled-components'

import { LinearProgress, withStyles } from '@material-ui/core'

export const Wrapper = styled.div`
  height: 1rem;
  width: 100%;
  margin: 8px 0;
  padding: 0 24px;
`

const MuiLinearProgress = withStyles({
  root: {
    height: 6,
    borderRadius: 10
  },
  colorPrimary: {
    background: '#C4C4C4'
  },
  barColorPrimary: {
    background: '#1BD15D'
  }
})(LinearProgress)

export const LinearStyled = styled(MuiLinearProgress)``
