import { ApiRepository } from 'repository/api-repository'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { RemoteCreateDoctorDocumentValidation } from 'service/usecases/create-doctor-document-validation/remote-create-doctor-document-validation'
import { DoctorDocumentValidationRepository } from 'repository/repositories/doctor-document-validation/doctor-document-validation-repository'
import { DoctorDocumentValidationService } from 'presentation/contexts/services/doctor-document-validation-service'
import { RemoteDeleteDoctorDocumentValidation } from 'service/usecases/delete-doctor-document-validation/remote-delete-doctor-document-validation'
import { RemoteUpdateDoctorDocumentValidation } from 'service/usecases/update-doctor-document-validation/remote-update-doctor-document-validation'

export default function makeDoctorDocumentValidationService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const doctorDocumentValidationRepository =
    new DoctorDocumentValidationRepository(apiRepository)

  const createDoctorDocumentValidation =
    new RemoteCreateDoctorDocumentValidation(doctorDocumentValidationRepository)

  const deleteDoctorDocumentValidation =
    new RemoteDeleteDoctorDocumentValidation(doctorDocumentValidationRepository)

  const updateDoctorDocumentValidation =
    new RemoteUpdateDoctorDocumentValidation(doctorDocumentValidationRepository)

  return new DoctorDocumentValidationService({
    createDoctorDocumentValidation,
    deleteDoctorDocumentValidation,
    updateDoctorDocumentValidation
  })
}
