import { UpdateAccompanying } from 'domain/usecases/accompanying/update-accompanying'
import { makeAutoObservable } from 'mobx'

type Services = {
  updateAccompanying: UpdateAccompanying
}

export class AccompanyingService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async updateAccompanying(
    params: UpdateAccompanying.Params
  ): Promise<UpdateAccompanying.Model> {
    return this.services.updateAccompanying.update(params)
  }
}

export default AccompanyingService
