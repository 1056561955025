import { UpdateLifeHabits } from 'domain/usecases/patient/update-life-habits'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteUpdateLifeHabits implements UpdateLifeHabits {
  constructor(private readonly patientRepository: PatientRepository) {}

  update(params: UpdateLifeHabits.Params): Promise<UpdateLifeHabits.Model> {
    return this.patientRepository.updateLifeHabits(params)
  }
}
