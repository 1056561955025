import { InvitePatientFromMv } from 'domain/usecases/patient/invite-patient-from-mv'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteInvitePatientFromMv implements InvitePatientFromMv {
  constructor(private readonly patientRepository: PatientRepository) {}

  add(params: InvitePatientFromMv.Params): Promise<InvitePatientFromMv.Model> {
    return this.patientRepository.invitePatientFromMV(params)
  }
}
