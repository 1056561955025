import { PhysicalActivityFrequency } from 'common/enum/physical-activity-frequency'

export const activityFrequencies = [
  {
    label: 'Muito ativo fisicamente',
    value: PhysicalActivityFrequency.VERY_ACTIVE
  },
  { label: 'Ativo fisicamente', value: PhysicalActivityFrequency.ACTIVE },
  { label: 'Sedentário', value: PhysicalActivityFrequency.SEDENTARY }
]
