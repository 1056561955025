import styled, { css, DefaultTheme } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  svg {
    height: 60vh;
  }
`

export const IndicatorGroup = styled.g<{ checked?: boolean }>`
  ${({ checked, theme }) =>
    css`
      cursor: pointer;
      ${!!checked && wrapperModifiers.checked(theme)}
    `}
`

const wrapperModifiers = {
  checked: (theme: DefaultTheme) => css`
    ellipse,
    circle {
      fill: ${theme.colors.red};
    }
  `
}
