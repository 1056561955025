import { UpdateUser } from 'domain/usecases/user/update-user'

import { UserRepository } from 'repository/repositories/user/user-repository'

export class RemoteUpdateUser implements UpdateUser {
  constructor(private readonly userRepository: UserRepository) {}

  update(params: UpdateUser.Params): Promise<UpdateUser.Model> {
    return this.userRepository.updateUser(params)
  }
}

export type UpdateUserModel = UpdateUser.Model
