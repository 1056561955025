import { LoadPatientPossibleMotherName } from 'domain/usecases/patient/load-patient-possible-mother-name'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteLoadPatientPossibleMotherName
  implements LoadPatientPossibleMotherName
{
  constructor(private readonly patientRepository: PatientRepository) {}

  load(
    params: LoadPatientPossibleMotherName.Params
  ): Promise<LoadPatientPossibleMotherName.Model> {
    return this.patientRepository.loadPatientPossibleMotherName(params)
  }
}
