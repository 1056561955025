import { PatientDocument } from 'common/enum/patient-document'
import { MedicalReportsLayout } from 'presentation/shared/layouts/MedicalReports'
import React from 'react'
import { useLocation } from 'react-router-dom'

export const MedicalReports = () => {
  const { state } = useLocation<{
    surgicalOrderId: number
    initialValues: {
      name: string
      type: PatientDocument
      document_id: number
    }[]
  }>()
  return <MedicalReportsLayout surgicalOrderId={state.surgicalOrderId} />
}
