import styled from 'styled-components'

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  h2 {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.gray};
    font-weight: 700;
  }

  & > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.4px;
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const RecoveryModeWrapper = styled.div``

export const RecoveryModeItem = styled.div<{ isActive?: boolean }>`
  height: 64px;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 10px;
  display: flex;
  text-align: left;
  padding: 12px 24px;
  align-items: center;
  color: ${({ isActive }) => (isActive ? '#1BD15D' : '#a0a49f')};
  cursor: pointer;
  position: relative;
  border: ${({ isActive }) => (isActive ? '1.5px solid #1BD15D' : undefined)};

  svg,
  img {
    height: 41px;
    width: 41px;

    path {
      fill: ${({ isActive }) => (isActive ? '#1BD15D' : '#a0a49f')} !important;
    }
  }

  div {
    height: 41px;
    margin-left: 18px;
    line-height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    span {
      font-weight: 600;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      word-break: break-all;
    }
  }

  & + div {
    margin-top: 24px;
  }
`

export const CountdownWrapper = styled.div`
  position: absolute;
  height: 64px !important;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center !important;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 10px;
  background-color: transparent;
`
