import React, { useEffect, useState } from 'react'
import Timeline, { TimelineProps } from '..'
import { TimelineStatusItem } from '../../NewTimeline'

export default function SurgicalOrderTimeline({ items }: TimelineProps) {
  const [statusList, setStatusList] = useState<TimelineStatusItem[]>([])

  useEffect(() => {
    setStatusList(
      items?.map((item) => ({
        status: item.status,
        isConcluded: true,
        isActive: true
      })) ?? []
    )
  }, [items])
  return <Timeline items={statusList} />
}
