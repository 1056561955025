import { LoadReligions } from 'domain/usecases/religion/load-religions'
import { ReligionRepository } from 'repository/interfaces/religion-repository'

export class RemoteLoadReligions implements LoadReligions {
  constructor(private readonly religionRepository: ReligionRepository) {}

  load(): Promise<LoadReligions.Model> {
    return this.religionRepository.loadReligions()
  }
}
