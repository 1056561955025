import styled from 'styled-components'

export const UploadListErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`

export const ListError = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.orange};
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.font.sizes.small};
  gap: 4px;
  align-items: center;
  justify-content: start;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.orange};
    }
    height: ${({ theme }) => theme.font.sizes.small};
    width: ${({ theme }) => theme.font.sizes.small};
    flex-shrink: 0;
  }
`
